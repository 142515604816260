<template>
    <div class="PlantCountQA">
        <div id="image-info" class="header-info-container">
            <h2
                :title="field_name + ' / ' + image_name"
                style="flex: 5;"
            >{{ this.trimText(field_name) }} / {{ this.trimText(image_name) }}</h2>
            <h2>Subimage {{ subimage_idx + 1 }} / {{ n_subimages }}</h2>
            <h2>{{ qa_status }}</h2>
        </div>
        <div id="container" class="ulabel-container"/>
    </div>
</template>

<script>
import { 
  batch_update_qa,
    get_all_field_tags, 
    get_binary_url, 
    get_next_image_to_qa, 
    get_next_outsourced_image_to_qa,
    get_single_image_tags,
    reject_qa,
    save_ppa_qa,
    save_row_qa,   
} from "../utils/api_endpoints.js";
import { ULABEL_SUBTASK_IDS } from "../utils/constants.js";
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    set_slider_values_in_ulabel_html,
    subimage_bounds_subtask 
} from "../utils/ulabel_utils.js";
import { ULabel } from "ulabel"
import { extend_line, extend_all } from "../utils/ulabel_extend_line";
import { get_subimage_qa_status } from "../utils/ddb_utils.js";
import { get_image_and_annotations, getMeta } from "../utils/annotation_loaders.js";
import { lock_image, unlock_image, get_username } from "../utils/qa_utils.js";
import { get_subimage_crop_from_idx, get_ulabel_initial_crop_from_bbox, make_ulabel_bbox } from "../utils/ulabel_anno_utils.js";
import { isInternalUser } from "../utils/cognito.js";
import { hide_navbar, show_navbar } from "../utils/nav_utils";
import { trimText } from "../utils/display";

export default {
    data() {
        return {
            field_name: "",
            field_json: null,
            image_name: "",
            subimage_idx: 0,
            ulabel: null,
            rgb_image_url: "",
            binary_image_url: "",
            rgb_displayed: true,
            subimage_qa_statuses: [], // list of all subimage qa statuses
            qa_status: "loading...",
            image_tags: "",
            row_annotations: [],
            n_h_subimages: 3,
            n_w_subimages: 3,
            n_subimages: 9,
            leave_qa_cycle: false,
            approve_all_subimages: false,
            field_tags: {},
            buffer_pct: 0,
        };
    },
    async mounted() {
        // Hide the entire navbar to force using the buttons
        hide_navbar();

        // Add event listeners
        document.addEventListener("keydown", this.keydownEventHandler);

        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;

        let next_image_found = true;
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) {
            // If image_name is in the route params, we use it
            this.image_name = this.$route.params.image_name;
            this.subimage_idx = this.$route.params.subimage_idx;
        } else {  
            // If no image_name is in the route params, we get the next available
            next_image_found = await this.load_next_image();
        }

        if (next_image_found) {
            await this.load_and_run_all();
        }
    },
    methods: {
        trimText,
        keydownEventHandler(e) {
            extend_line(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            extend_all(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.binary_image_url);
                } else {
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
            if (e.key == "Enter") {
                document.getElementById("approve").click();
            }
        },
        navigate_away() {
            this.beforeDestoryHandler();

            let dest = isInternalUser() ? "Progress" : "Home";
            this.$router.push({
                name: dest,
            });
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Un-hide the navbar
            show_navbar();
        },
        async go_without_saving(idx_change) {
            // Navigate to next/previous subimage
            let new_subimage_idx = this.subimage_idx + idx_change;

            // Prevent out of bounds navigation
            if (new_subimage_idx >= 0 && new_subimage_idx < this.n_subimages) {
                this.subimage_idx = new_subimage_idx;
                await this.reload_ulabel();
            }
        },
        update_qa_status() {
            this.qa_status = this.subimage_qa_statuses[this.subimage_idx]["keypoint_qa"];
        },

        /**
         * Load the next image to QA
         * 
         * @returns {Promise<boolean>} Whether the next image was loaded successfully
         */
        async load_next_image() {
            let next_image;
            if (isInternalUser()) {
                next_image = await get_next_image_to_qa(
                    this.field_json,
                    "",
                    "keypoint_qa",
                )
            } else {
                // External users only get outsourced images
                next_image = await get_next_outsourced_image_to_qa()
            }
            console.log(next_image);
            // TODO better constants
            if (next_image == "Nothing to QA.") {
                this.navigate_away();
                return false;
            }
            this.image_name = next_image.image_name;
            this.subimage_idx = next_image.subimage_idx;
            return true;
        },

        /**
         * Load all data and run ULabel
         * 
         */
        async load_and_run_all(reload_ulabel = false) {
            // Kick off all the async functions
            let lock_acq_prom = lock_image(this.field_json, this.image_name);
            let image_tags_promise = get_single_image_tags(this.field_json, this.image_name);
            let field_tags_promise = get_all_field_tags(this.field_json);
            let subimage_qa_status_promise = get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
            let image_promise = this.get_image();

            // Wait for lock to be acquired, exit if not 
            let lock_acquired = await lock_acq_prom;
            if (!lock_acquired) {
                alert("Image is locked by another user. Please try again later.");
                this.navigate_away();
            }

            // Await other data
            let data = await subimage_qa_status_promise;
            this.subimage_qa_statuses = data["subimages"];

            // Get image tags and field tags
            this.image_tags = await image_tags_promise;
            this.field_tags = await field_tags_promise;
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            } catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct);

            // Wait for image and annotations to load
            await image_promise
            
            if (reload_ulabel) {
                // Set new image
                this.ulabel.swap_frame_image(this.rgb_image_url);
                this.rgb_displayed = true;
                // Set new annotations
                this.ulabel.set_annotations(this.preproc_annotations(this.annotations), ULABEL_SUBTASK_IDS.PLANT_COUNT_QA);
                this.ulabel.set_annotations(this.row_annotations, ULABEL_SUBTASK_IDS.ROW_QA);
                // Set new slider values
                set_slider_values_in_ulabel_html(this.image_tags, this.field_tags);
                await this.reload_ulabel();
            } else {
                // Start ULabel
                this.startULabel();
            }
        },

        /**
         * Reload ULabel with a new subimage
         */
        async reload_ulabel() {
            this.update_qa_status();
            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)

            // Update ULabel with new subimage bounds
            this.ulabel.set_annotations([subimage_anno], "subimage_bounds");
            this.ulabel["config"]["initial_crop"] = ulabel_initial_crop;
            this.ulabel.show_initial_crop();
        },

        read_size_cookie() {
            let subtask_name = ULABEL_SUBTASK_IDS.PLANT_COUNT_QA
            let cookie_name = subtask_name + "_size=";
            let cookie_array = document.cookie.split(";");
            for (let i = 0; i < cookie_array.length; i++) {
                let current_cookie = cookie_array[i];
                //while there's whitespace at the front of the cookie, loop through and remove it
                while (current_cookie.charAt(0) == " ") {
                    current_cookie = current_cookie.substring(1);
                }
                if (current_cookie.indexOf(cookie_name) == 0) {
                    return parseFloat(current_cookie.substring(cookie_name.length, current_cookie.length))
                }
            }
            return null
        },

        async get_image() {
            // Start async tasks
            // Row annotations
            let params = {
                field_name: this.field_name,
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "row_annotations"
            }
            let row_annotations_promise = get_image_and_annotations(params);
            // Keypoint annotations
            params.annotation_dir = "annotations";
            let keypoint_annotations_promise = get_image_and_annotations(params);

            // Binary url
            let binary_image_url_promise = get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
            
            // Collect all promises
            // Row annotations
            let ret = await row_annotations_promise;
            this.rgb_image_url = ret[0];
            this.row_annotations = ret[1];
            // Keypoint annotations
            ret = await keypoint_annotations_promise;
            this.annotations = ret[1];
            // Binary url
            this.binary_image_url = await binary_image_url_promise;

            // Image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;
        },
        async on_submit(annotations) {
            // Get slider values
            let slider_values = get_slider_values_from_ulabel_html();

            console.log(annotations);
            annotations = this.preproc_annotations(annotations);
            // Semantics to keep formatting consistent.
            let keypoint_post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"][ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]],
                    },
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                subimage_idx: this.subimage_idx,
                keypoint_slider: slider_values.keypoint_confidence_slider_val,
            };
            // Add row distance slider value if it exists
            if (slider_values.row_dist_slider_val) {
                keypoint_post_annotations_params["row_distance_slider"] = slider_values.row_dist_slider_val;
            }

            let row_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"][ULABEL_SUBTASK_IDS.ROW_QA]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                not_done: true, // Don't mark row qa as done
            }

            // Save points (and rows if Internal User)
            await save_ppa_qa(keypoint_post_annotations_params);
            if (isInternalUser()) {
                await save_row_qa(row_annotations_params);
            }

            // Prepare to navigate to next job.
            let next_idx = this.next_incomplete_subimage();

            if (next_idx >= this.n_subimages || this.leave_qa_cycle || this.approve_all_subimages) {
                // Move to the next image, or exit the QA cycle
                // If submitting all subimages, approve all subimages
                if (this.approve_all_subimages) {
                    let params = {
                        field_json: this.field_json,
                        update_items: []
                    }
                    for (let subimage_idx = 0; subimage_idx < this.n_subimages; subimage_idx++) {
                        params.update_items.push({
                            "image_name": this.image_name,
                            "qa_type": "keypoint_qa",
                            "qa_val": "approved",
                            "subimage_idx": subimage_idx,
                        }); // approve each subimage
                    }
                    await batch_update_qa(params);
                    // Reset the flag
                    this.approve_all_subimages = false;
                }

                // If everything occurred as expected, unlock 
                await unlock_image(this.field_json, this.image_name);

                if (this.leave_qa_cycle) {
                    this.navigate_away(); 
                } else if (await this.load_next_image()) {
                    // Navigate to next image
                    await this.load_and_run_all(true);
                }
            } else {
                // Continue to next subimage
                // Update locally stored annotations
                this.annotations = annotations["annotations"][ULABEL_SUBTASK_IDS.PLANT_COUNT_QA];

                // Locally update qa_statuses
                this.subimage_qa_statuses[this.subimage_idx]["keypoint_qa"] = "approved";

                // Navigate to next subimage
                this.subimage_idx = next_idx;
                await this.reload_ulabel();
            }
        },
        async approveAllSubimages(annotations) {
            this.approve_all_subimages = true;
            await this.on_submit(annotations);
        },
        async exitWithoutSaving() {
            // Unlock image and navigate away
            await unlock_image(this.field_json, this.image_name);
            this.navigate_away();
        },
        async rejectAndContinue() {
            // Reject current subimage
            await reject_qa(this.field_json, this.image_name, "keypoint_qa", this.subimage_idx);
            // Locally update qa_statuses
            this.subimage_qa_statuses[this.subimage_idx]["keypoint_qa"] = "rejected";

            // Navigate to next job.
            let next_idx = this.next_incomplete_subimage();
            if (next_idx >= this.n_subimages) {
                // Navigate to next image
                await this.load_next_image();
                await this.load_and_run_all(true);
            } else {
                // Navigate to next subimage
                this.subimage_idx = next_idx;
                await this.reload_ulabel();
            }
        },
        async nextSubimage() {
            await this.go_without_saving(1);
        },
        async previousSubimage() {
            await this.go_without_saving(-1);
        },
        getSubmitButtons() {
            let args = {
                "set_saved": true,
                "size_factor": 0.8,
            }
            return [
                {
                    "name": "Previous Subimage",
                    "hook": this.previousSubimage,
                    "color": "gray",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Next Subimage",
                    "hook": this.nextSubimage,
                    "color": "lightgray",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Exit",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Approve",
                    "hook": this.on_submit,
                    "color": "lightgreen",
                    "row_number": 1,
                    ...args,
                },
                {
                    "name": "Approve All",
                    "hook": this.approveAllSubimages,
                    "color": "lightblue",
                    "row_number": 1,
                    ...args,
                },
                {
                    "name": "Reject",
                    "hook": this.rejectAndContinue,
                    "color": "pink",
                    "row_number": 1,
                    ...args,
                },
            ];
        },
        next_incomplete_subimage() {
            // Return index of next not_done subimage, or return
            // the total number of subimages so that we progress
            // to the next qa job
            for (let next_idx = (this.subimage_idx + 1).valueOf(); next_idx < this.n_subimages; next_idx++) {
                if (this.subimage_qa_statuses[next_idx]["keypoint_qa"] == "not_done") {
                    return next_idx;
                }
            }
            return this.n_subimages;
        },
        preproc_annotations(annotations) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < annotations.length; i++) {
                annotations[i].line_size = anno_size;
            }
            return annotations;
        },
        startULabel() {
            this.update_qa_status();

            let annos = this.preproc_annotations(this.annotations);

            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)
            let subtasks = {
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(
                    this.row_annotations,
                    {
                        inactive_opacity: 0.0
                    }
                ),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask([subimage_anno]),
            };
            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }

            // Initial ULabel configuration
            const config = get_config_data(
                "container",
                this.rgb_image_url,
                email,
                subtasks,
                this.getSubmitButtons(),
                this.image_tags,
                this.field_tags,
                ulabel_initial_crop,
            )
            let ulabel = new ULabel(config);
            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                /*
                So this is absolutely horrible practice to deal with bonkers behavior 
                Basically the .on function in ulabel uses the functions .name property to reassign the callback
                For some reason vue-cli-service build sets all function.name properties to 'value'
                which breaks this callback behavior.
                Manually changing the property like this fixes it for the build version.
                */
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                )
            });
        },
    },
    beforeDestory() {
        this.beforeDestoryHandler();
    }
};
</script>

<style scoped>
#container #toolbox div.keypoint-slider div.keypoint-slider-holder {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
}

#container #toolbox div.keypoint-slider div.keypoint-slider-holder span.increment {
    bottom: 0.5em;
}
</style>
