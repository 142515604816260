<template>
    <div id="navbar">
        <b-navbar toggleable="sm" type="light" variant="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <b-navbar-brand href="/">
                <img src="../assets/sentera-logo.png" style="width:50px;height:50px">
                Stand QA Tool
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Navbar for Internal Users -->
                <b-navbar-nav id="internal-navbar" v-bind:class="internalNavbarClass()">
                    <b-nav-item href="/login">Logout</b-nav-item>
                    <b-nav-item href="/upload">Upload</b-nav-item>
                    <b-nav-item href="/field_overview">Field Overview</b-nav-item>
                    <b-nav-item href="/row_qa">Row QA</b-nav-item>
                    <b-nav-item href="/plant_count_qa">Plant Count QA</b-nav-item>
                    <b-nav-item href="/gender_qa">Gender QA</b-nav-item>
                    <b-nav-item href="/progress">Progress</b-nav-item>
                    <b-nav-item href="/grid">Grid</b-nav-item>
                    <b-nav-item href="/analytic_details">Analytic Details</b-nav-item>
                </b-navbar-nav>
                <!-- Navbar for External Users -->
                <b-navbar-nav id="external-navbar" v-bind:class="externalNavbarClass()">
                    <b-nav-item href="/login">Logout</b-nav-item>
                    <b-nav-item href="/plant_count_qa">Plant Count QA</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { isInternalUser } from '../utils/cognito'

export default {
    methods: {
        internalNavbarClass() {
            return isInternalUser() ? "" : "hidden";
        },
        externalNavbarClass() {
            return isInternalUser() ? "hidden" : "";
        }
    }
}
</script>

<style scoped>
    p { 
        margin: 0;
        font-size: 13px; 
    }

    .hidden {
        display: none;
    }

    div.info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
    }
</style>
