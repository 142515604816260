<template>
    <div class="grid-container">
        <div class="filter-group">
            <label for="statusFilter">Status Filter: </label>
            <select id="statusFilter" v-model="statusFilter" @change="filterData">
                <option value="">All</option>
                <option value="completed">Completed</option>
                <option value="rejected">Abandoned</option>
                <option value="not_done">Not Done</option>
            </select>

            <label for="runTypeFilter">Run Type Filter: </label>
            <select id="runTypeFilter" v-model="runTypeFilter" @change="filterData">
                <option value="">All</option>
                <option value="single_variety">Single Variety</option>
                <option value="male_female">Male / Female</option>
            </select>

            <label for="cropTypeFilter">Crop Type Filter: </label>
            <select id="cropTypeFilter" v-model="cropTypeFilter" @change="filterData">
                <option value="">All</option>
                <option value="corn">Corn</option>
                <option value="cotton">Cotton</option>
                <option value="soybeans">Soybeans</option>
            </select>
            <label for="outsourcedFilter">Outsourced Filter: </label>
            <select id="outsourcedFilter" v-model="outsourcedFilter" @change="filterData">
                <option value="">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </select>
        </div>

        <div id="grid" class="grid-box"></div>
    </div>
</template>

<script>
import { Grid, html } from 'gridjs';
import "gridjs/dist/theme/mermaid.css";
import { getAnalytics, getAnalyticJson } from "../api/analytics";

export default {
    name: 'DataGrid',
    data() {
        return {
            analyticsData: [],  
            gridInstance: null,
            statusFilter: '', 
            runTypeFilter: '',
            cropTypeFilter: '', 
            outsourcedFilter: '',
        };
    },
    mounted() {
        this.loadGridData();
    },
    methods: {
        async loadGridData() {
            try {
                const analytics = await getAnalytics()
                const gridData = analytics.map(item => ({
                    id: item.id,
                    organizationName: item.organization_name,
                    fieldName: item.field_name,
                    status: item.status,
                    cropType: item.crop_type,
                    runType: item.stand_type,
                    outsourced: item.outsourced,
                    priority: item.priority,
                    createdAt: item.created_at,
                    select: "🚀",
                }));

                this.analyticsData = gridData;
        
                // Initialize the Grid.js grid
                this.gridInstance = new Grid({
                    columns: this.getGridColumns(),
                    data: gridData,
                    pagination: {
                        limit: 10
                    },
                    search: {
                        enabled: true,
                        selector: (cell, rowIndex, columnIndex) => {
                            // Limit search to FieldName (index 2) and OrganizationName (index 1)
                            if (columnIndex === 2 || columnIndex === 1) {
                                return cell; // Return the cell value for search
                            }
                            return ''; 
                        }
                    },
                    resizable: true,
                }).render(document.getElementById("grid"))
                
                this.attachDelegatedEventListener()
            } catch (error) {
                console.error('Error loading grid data', error);
            }
        },
        getGridColumns() {
            return [
                { id: 'id', name: 'ID', width: 85, sort: true },
                { id: 'organizationName', name: 'Organization', width: 250},
                { id: 'fieldName', name: 'Field'},
                { 
                    id: 'status', 
                    name: 'Status',
                    formatter: (cell) => {
                        let backgroundColor = '';
                        let text = '';
                        if (cell === 'completed') {
                            backgroundColor = '#28a745';  // Green
                            text = 'Completed';
                        } else if (cell === 'abandoned') {
                            backgroundColor = '#d2042d';    // Red
                            text = 'Abandoned';
                        } else if (cell === 'in_progress') {
                            backgroundColor = '#FFEB3B';    // Yellow
                            text = 'In Progress';
                        } else {
                            backgroundColor = 'gray';  
                            text = 'Not Started'
                        }
                        return html(
                            `<span style="background-color: ${backgroundColor}; color: white; padding: 5px 10px; border-radius: 12px; font-weight: 500">${text}</span>`
                        );
                    },
                    width: 150
                },
                { 
                    id: 'runType', 
                    name: 'Run Type',
                    formatter: (cell) => {
                        let backgroundColor = cell === 'single_variety' ? '#ffc107': '#ff6f61'; // yellow for single variety, coral for male/female
                        let displayText = cell === 'single_variety' ? 'Single Variety' : 'Male / Female';
                        
                        return html(
                            `<span style="background-color: ${backgroundColor}; color: white; padding: 5px 10px; border-radius: 12px; font-weight: 500">
                                ${displayText}
                            </span>`
                        );
                    },
                    width: 175
                },
                { 
                    id: 'cropType', 
                    name: 'Crop Type', 
                    formatter: (cell) => {
                        let backgroundColor = '';
                        let displayText = '';

                        switch(cell) {
                            case 'corn':
                                backgroundColor = '#9acd32';  // YellowGreen 
                                displayText = 'Corn';
                                break;
                            case 'cotton':
                                backgroundColor = '#6b8e23';  // OliveDrab
                                displayText = 'Cotton';
                                break;
                            case 'soybeans':
                                backgroundColor = '#556b2f';  // DarkOliveGreen
                                displayText = 'Soybeans';
                                break;
                            default:
                                backgroundColor = '#8fbc8f';  // DarkSeaGreen 
                                displayText = cell.charAt(0).toUpperCase() + cell.slice(1); // Capitalize first letter of unknown crop types
                                break;
                        }

                        return html(
                            `<span style="background-color: ${backgroundColor}; color: white; padding: 5px 10px; border-radius: 12px; font-weight: 500">
                                ${displayText}
                            </span>`
                        );
                    },
                    width: 130
                },
                { 
                    id: 'outsourced', 
                    name: 'Outsourced',
                    formatter: (cell) => {
                        let displayText = cell ? 'Yes' : 'No';
                        let backgroundColor = cell ? '#28a745' : 'gray';

                        return html(
                            `<span style="background-color: ${backgroundColor}; color: white; padding: 5px 10px; border-radius: 12px; font-weight: 500">
                                ${displayText}
                            </span>`
                        );
                    },
                    width: 140
                },
                { id: 'priority', name: 'Priority', width: 125, sort: true},
                {
                    id: 'createdAt',
                    name: 'Created At',
                    formatter: (cell) => {
                        const date = new Date(cell);
                        return html(
                            `<span style="color: #6c757d; font-style: italic;">
                                ${date.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })}
                            </span>`
                        );
                    },
                    width: 225,
                    sort: true
                },
                {
                    id: 'select',
                    name: "Select",
                    formatter: (cell, row) => {
                        return html(
                            `<a href="#" class="launch-link" 
                                analytic-id="${row.cells[0].data}"
                                field-name="${row.cells[2].data}"
                                title="Go to details page">
                                🚀
                            </a>`
                        );
                    },
                    width: 90
                }
            ];
        },
        filterData() {
            this.filteredData = this.analyticsData.filter(item => {
                if (this.statusFilter && item.status !== this.statusFilter) {
                    return false;
                }
                if (this.runTypeFilter && item.runType !== this.runTypeFilter) {
                    return false;
                }
                if (this.cropTypeFilter && item.cropType !== this.cropTypeFilter) {
                    return false;
                }
                if (this.outsourcedFilter && item.outsourced != (this.outsourcedFilter == 'yes')) {
                    return false;
                }
                return true;
            });

            // Re-render the grid with the filtered data
            this.gridInstance.updateConfig({
                data: this.filteredData
            }).forceRender();
        },
        attachDelegatedEventListener() {
            // Attach a click event listener to the grid container (event delegation)
            const gridContainer = document.getElementById("grid");
            gridContainer.addEventListener('click', (event) => {
                // Check if the clicked element is a launch link
                if (event.target.classList.contains('launch-link')) {
                    const analyticId = event.target.getAttribute('analytic-id');
                    const fieldName = event.target.getAttribute('field-name');
                    this.launchLink(analyticId, fieldName); 
                }
            });
        },
        async launchLink(analyticId, fieldName) {
            let fieldJSON = await getAnalyticJson(analyticId);
            this.$store.commit("update$field_json", fieldJSON);
            this.$store.commit("update$field_name", fieldName);
            this.$store.commit("update$analytic_id", analyticId);
            this.$router.push({name: "Analytic Details"});
        }
    }
}
</script>

<style scoped>
.grid-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.grid-box {
    width: 95%;
}

.filter-group {
    display: flex;
    justify-content: center;
    gap: 20px; 
    flex-wrap: wrap; 
    padding: 30px; 
    border-radius: 10px; 
    align-items: center; 
}

.filter-group label {
    font-weight: bold;
    color: #333;
    margin-right: 10px; 
    font-size: 16px; 
}

.filter-group select {
    padding: 8px 12px; 
    border: 1px solid #ccc; 
    border-radius: 8px;
    font-size: 16px; 
    height: 40px; 
    line-height: 1.5; 
    transition: border-color 0.3s ease;
}

.filter-group select:focus {
    border-color: #007bff; 
    outline: none; 
}

.filter-group select:hover {
    border-color: #0056b3;
}
</style>
