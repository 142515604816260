// Utility functions for QA jobs
import { hit_terraform_api } from "./api_utils.js";

export async function lock_image(field_json, image_name) {
    let params = {
        field_json: field_json,
        update_items: [{
            "image_name": image_name,
            "qa_type": "IS_LOCKED",
            "qa_val": true,
        }],
        check_lock_cond: true,
    };
    try {
        let response = await hit_terraform_api(params, "batch_update_qa");
        return response.status == 200
    }
    catch {
        return false;
    }
}

export async function unlock_image(field_json, image_name) { 
    let params = {
        field_json: field_json,
        update_items: [{
            "image_name": image_name,
            "qa_type": "IS_LOCKED", 
            "qa_val": false,
        }],
    };
    await hit_terraform_api(params, "batch_update_qa");
}

/**
 * Util to truncate a full email address down to
 * the first name and last name first initial
 * 
 * @param {String} user full username (email address)
 * @returns {String} truncated username, ie trevor.b
 */
export function truncate_username(user) {
    return user.replace(/(.*)\.(.).+@.+/, "$1.$2");
}

export function get_username(page) {
    return page.$store.state.$user;
}
