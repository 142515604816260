// ULabel Subtask Utilities
import { 
    DEFAULT_KEYPOINT_CONFIDENCE_SLIDER_VALUE,
    DEFAULT_ROW_DIST_SLIDER_VAL,
    ULABEL_INITIAL_LINE_SIZE,
    ULABEL_SUBTASK_DISPLAY_NAMES, 
    ULABEL_SUBTASK_IDS,
    ULABEL_TOOLBOX_ITEMS 
} from "./constants"
import { isInternalUser } from "./cognito"
import $ from "jquery"

/**
 * Util to get the most recent action 
 * in a specific ulabel subtask, or return null
 * if no actions have been performed.
 * 
 * @param {*} ulabel 
 * @param {string} subtask_name 
 * @returns most_recent_action
 */
export function get_most_recent_action(ulabel, subtask_name) {
    let action_stream = ulabel.subtasks[subtask_name]["actions"]["stream"];
    let annotation_ids = ulabel.subtasks[subtask_name]["annotations"]["ordering"];
    let most_recent_action = null;
    if (action_stream.length > 0 || annotation_ids.length > 0) {
        // No actions hack, TODO do this better
        if (action_stream.length == 0) {
            // actid goes to the most last object
            most_recent_action = {
                "act_type": "bypass",
                "redo_payload": {
                    "actid": annotation_ids[annotation_ids.length - 1]
                }
            };
        } else {
            most_recent_action = action_stream[action_stream.length - 1];
        }
    }
    return most_recent_action;
}

export function ppa_qa_subtask(resume_from_annos, args) { 
    return { 
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.PLANT_COUNT_QA], 
        classes: [ 
            {
                name: "Plant",
                color: "yellow",
                id: 1,
            }
        ],
        allowed_modes: ["point", "delete_bbox", "delete_polygon"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}

export function gendered_row_qa_subtask(resume_from_annos, args) {
    return {
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.ROW_QA],
        classes: [
            {
                name: "Male",
                color: "blue",
                id: 10,
                keybind: "1",
            },
            {
                name: "Female",
                color: "white",
                id: 11,
                keybind: "2",
            },
        ],
        allowed_modes: ["polyline"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}

export function subimage_bounds_subtask(resume_from_annos, args) {
    return {
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS],
        classes: [
            {
                name: "Subimage Bound",
                color: "black",
                id: 21,
            },
            {
                name: "Rejected Subimage Bound",
                color: "red",
                id: 22,
            },
        ],
        allowed_modes: ["bbox"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}

/**
 * Get the default toolbox item order for the ULabel application based on user group
 * 
 * @returns {Array} The default toolbox item order for the ULabel application
 */
export function get_toolbox_item_order() {
    // Configure the toolbox item order based on user group
    let toolbox_order = [
        ULABEL_TOOLBOX_ITEMS.SubmitButtons,
        ULABEL_TOOLBOX_ITEMS.ModeSelect,
        ULABEL_TOOLBOX_ITEMS.AnnotationResize,
        ULABEL_TOOLBOX_ITEMS.AnnotationID,
        ULABEL_TOOLBOX_ITEMS.ClassCounter,
        ULABEL_TOOLBOX_ITEMS.KeypointSlider,
    ];
    if (isInternalUser()) {
        toolbox_order.push(ULABEL_TOOLBOX_ITEMS.FilterDistance);
    }
    // At the bottom because its big and ugly
    toolbox_order.push(ULABEL_TOOLBOX_ITEMS.RecolorActive);
    return toolbox_order
}

/**
 * Get the slider values for the ULabel application from the image and field tags
 * 
 * @param {object} image_tags 
 * @param {object} field_tags
 * @returns {object} The slider values for the ULabel application 
 */
export function get_slider_values_from_tags(image_tags, field_tags) {
    let keypoint_confidence_slider_val;
    let row_dist_slider_val;

    if ("keypoint_slider_val" in image_tags) {
        keypoint_confidence_slider_val = parseInt(image_tags["keypoint_slider_val"]["value"]);
    } else if ("keypoint_confidence_slider_default_value" in field_tags) {
        keypoint_confidence_slider_val = parseInt(field_tags["keypoint_confidence_slider_default_value"]["value"]);
    } else {
        keypoint_confidence_slider_val = DEFAULT_KEYPOINT_CONFIDENCE_SLIDER_VALUE;
    }

    // This is invariant to whether or not QA has been performed
    if ("row_dist_slider_val" in image_tags) {
        row_dist_slider_val = parseInt(image_tags["row_dist_slider_val"]["value"]);
    } else if ("row_distance_slider_default_value" in field_tags) {
        row_dist_slider_val = parseInt(field_tags["row_distance_slider_default_value"]["value"]);
    } else {
        row_dist_slider_val = DEFAULT_ROW_DIST_SLIDER_VAL;
    }

    return {
        "keypoint_confidence_slider_val": keypoint_confidence_slider_val,
        "row_dist_slider_val": row_dist_slider_val
    }
}

/**
 * Get the slider values for the ULabel application
 * 
 * @returns {object} The slider values {keypoint_confidence_slider_val, row_dist_slider_val}
 */
export function get_slider_values_from_ulabel_html() {
    // Get keypoint slider value
    let keypoint_slider_val = null;
    try {
        keypoint_slider_val = $("input#keypoint-slider")[0].value;
    } catch (e) { 
        console.log("Error getting keypoint_slider_val:", e);
    }

    // Get row distance slider value
    let row_dist_slider_val = null;
    try {
        row_dist_slider_val = $("input#filter-row-distance-closest_row")[0].value;
    } catch (e) { 
        console.log("Error getting row_dist_slider_val:", e);
    }

    return {
        "keypoint_confidence_slider_val": keypoint_slider_val,
        "row_dist_slider_val": row_dist_slider_val
    }
}


/**
 * Get slider value from tags and then set the slider values in the ULabel HTML
 * 
 * @param {object} image_tags 
 * @param {object} field_tags 
 */
export function set_slider_values_in_ulabel_html(image_tags, field_tags) {
    let slider_values = get_slider_values_from_tags(image_tags, field_tags);
    try {
        // Set keypoint slider value
        $("input#keypoint-slider")[0].value = slider_values.keypoint_confidence_slider_val;
        // Toggle the slider by incrementing/decrementing the value
        $("button#keypoint-slider-inc-button")[0].click();
        $("button#keypoint-slider-dec-button")[0].click();
    } catch (e) { 
        console.log("Error setting keypoint_slider_val:", e);
    }

    try {
        // Set row distance slider value
        $("input#filter-row-distance-closest_row")[0].value = slider_values.row_dist_slider_val;
        // Toggle the slider by incrementing/decrementing the value
        $("button#filter-row-distance-closest_row-inc-button")[0].click();
        $("button#filter-row-distance-closest_row-dec-button")[0].click();
    } catch (e) { 
        console.log("Error setting row_dist_slider_val:", e);
    }
}


/**
 * Get the configuration data for the ULabel application from the image and field tags
 * 
 * @param {string} container_id container ID for the ULabel application
 * @param {string} image_data image string
 * @param {string} username username
 * @param {object} subtasks ULabel Subtask config object
 * @param {Array<object>} submit_buttons list of ULabel SubmitButton objects
 * @param {object} image_tags image tags
 * @param {object} field_tags field tags
 * @param {object} initial_crop ULabel InitialCrop object
 * @returns {object} The configuration data for the ULabel application
 */
export function get_config_data(
    container_id,
    image_data,
    username, 
    subtasks,
    submit_buttons,
    image_tags, 
    field_tags,
    initial_crop = null,
) {
    let slider_values = get_slider_values_from_tags(image_tags, field_tags);
    let ret = {
        // Required fields
        "container_id": container_id,
        "image_data": image_data,
        "username": username,
        "subtasks": subtasks,
        "submit_buttons": submit_buttons,
        // Optional fields
        "toolbox_order": get_toolbox_item_order(),
        "create_bbox_on_initial_crop": "|",
        "initial_line_size": ULABEL_INITIAL_LINE_SIZE,
        "keypoint_slider_default_value": slider_values.keypoint_confidence_slider_val / 100,
        "n_annos_per_canvas": 1000, // Performance optimization
        "initial_crop": initial_crop,
    }
    if (isInternalUser()) {
        // Add the distance filter toolbox item configuration
        ret["distance_filter_toolbox_item"] = {
            "filter_during_polyline_move": false, // Boost performance by not filtering during move
            "disable_multi_class_mode": true,
            "filter_max": 400, // px
            "step_value": 1, // px
            "default_values": {
                "closest_row": {
                    "distance": slider_values.row_dist_slider_val
                }
            },
        }
    }
    return ret;
}