import Vue from 'vue'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuetify from 'vuetify'
import { DEFAULT_USER } from './utils/constants';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex);
Vue.use(Vuetify)

Vue.config.productionTip = false
const store = new Vuex.Store({
    state: {
        $count: 0,
        $field_name: "",
        $field_json: "",
        $subimage_idx: -1,
        $image_name: "",
        $n_h_subimages: 3,
        $n_w_subimages: 3,
        $binary_set: "",
        $progress_scroll: 0,
        $annotations: {},
        $user: DEFAULT_USER,
        $break: false,
        $grid_scroll: 0,
        $gender_qa: false,
        $analytic_id: null,
    },
    mutations: {
        update$count: function (state, new_count) {
            state.$count = new_count;
        },
        update$field_name: function(state, new_field_name) {
            state.$field_name = new_field_name;
        },
        update$field_json: function(state, new_field_json) {
            state.$field_json = new_field_json;
        },
        update$image_name: function(state, new_image_name) {
            state.$image_name = new_image_name;
        },
        update$subimage_idx: function(state, new_subimage_idx) {
            state.$subimage_idx = new_subimage_idx;
        },
        update$n_h_subimages: function(state, new_n_h_subimages) {
            state.$n_h_subimages = new_n_h_subimages;
        },
        update$n_w_subimages: function(state, new_n_w_subimages) {
            state.$n_w_subimages = new_n_w_subimages;
        },
        update$binary_set: function(state, new_binary_set) {
            state.$binary_set = new_binary_set;
        },
        update$progress_scroll: function(state, new_progress_scroll) {
            state.$progress_scroll = new_progress_scroll;
        },
        update$annotations: function(state, new_annotations) {
            state.$annotations = new_annotations;
        },
        update$user: function(state, new_user) {
            state.$user = new_user;
        },
        update$break: function(state, new_break) {
            state.$break = new_break;
        },
        update$grid_scroll: function(state, new_grid_scroll) {
            state.$grid_scroll = new_grid_scroll;
        },
        update$gender_qa: function(state, new_gender_qa) {
            state.$gender_qa = new_gender_qa;
        },
        update$analytic_id: function(state, new_analytic_id) {
            state.$analytic_id = new_analytic_id;
        },
    },
    plugins: [createPersistedState()]
})

new Vue({
    store: store,
    render: h => h(App),
    router,
}).$mount('#app')
