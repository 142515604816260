<template>
    <v-container class="sign-in-container">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-avatar>
                            <v-icon>mdi-lock-outline</v-icon>
                        </v-avatar>
                        <span class="headline">{{ login_mode }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="handleSubmit">
                            <v-text-field
                                v-model="username"
                                label="Username"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                label="Password"
                                type="password"
                                required
                                title="Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                            ></v-text-field>
                            <!-- If in SIGN_UP or RESET_PASSWORD mode, show text box with password reqs -->
                            <div
                                v-if="login_mode === LOGIN_MODES.SIGN_UP || login_mode === LOGIN_MODES.RESET_PASSWORD"
                                label="Password Requirements"
                            
                            >Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.</div>                            
                            <br>
                            <v-btn type="submit" color="blue">Sign In</v-btn>
                        </v-form>
                    <!-- When in Sign In mode or Reset Password Mode, show a button to toggle Sign Up mode  -->
                    <div class="login-mode-container" v-if="login_mode === LOGIN_MODES.SIGN_IN || login_mode === LOGIN_MODES.RESET_PASSWORD">
                        <button class="login-mode-button" @click="setLoginMode(LOGIN_MODES.SIGN_UP)">
                            {{ "Don't have an account? " + LOGIN_MODES.SIGN_UP }}
                        </button>
                    </div>
                    <!-- When in Sign Up mode, show the link to Sign In mode -->
                    <div class="login-mode-container" v-if="login_mode === LOGIN_MODES.SIGN_UP">
                        <button class="login-mode-button" @click="setLoginMode(LOGIN_MODES.SIGN_IN)">
                            {{ "Already have an account? " + LOGIN_MODES.SIGN_IN }}
                        </button>
                    </div>
                    <!-- When in Sign In mode, show a link to Reset Password -->
                    <div class="login-mode-container" v-if="login_mode === LOGIN_MODES.SIGN_IN">
                        <button class="login-mode-button" @click="setLoginMode(LOGIN_MODES.RESET_PASSWORD)">
                            {{ "Forgot your password? " + LOGIN_MODES.RESET_PASSWORD }}
                        </button>
                    </div>
                    <!-- Show app version -->
                    <v-card-subtitle>Stand QA Tool v{{ STAND_QA_VERSION }}</v-card-subtitle>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { authenticate, confirm, register, resetPassword , logout, isInternalUser} from "../utils/cognito";
import { hide_navbar, show_navbar } from "../utils/nav_utils";
import { LOGIN_MODES } from "../utils/constants";
import { loadVuetifyCSS, unloadVuetifyCSS } from '../utils/vuetify_utils';
import { STAND_QA_VERSION } from '../version';

export default {
    name: 'Login',
    data() {
        return {
            login_mode: LOGIN_MODES.SIGN_IN,
            username: '',
            password: ''
        };
    },
    mounted() {
        // Log the user out if they are already logged in
        logout();
        // Hide the internal and external navbar links
        hide_navbar(true, true);
        // Load Vuetify CSS
        loadVuetifyCSS();
    },
    methods: {
        loginSuccess(user) {
            // Store user name
            this.$store.commit("update$user", user);
            // Show proper navbar links
            if (isInternalUser()) {
                show_navbar(true, false);
            } else {
                show_navbar(false, true);
            }
            // Go to the home page
            this.$router.push({ name: "Home" });
        },

        /**
         * Handle verifying the user's email address with a confirmation code
         * 
         * @param {string} email email address
         * @param {string} password password
         */
        async handleVerification (email, password) {
            // Confirm the sign up by sending the confirmation code
            let code = prompt("Please enter the confirmation code sent to your email: " + email);
            
            // Loop until the user enters the correct code or cancels
            while (code !== null) {
                if (await confirm(email, password, code)) {
                    // If the confirmation is successful, break the loop
                    return this.loginSuccess(email);
                }
                // If the confirmation is unsuccessful, prompt the user for the code again
                code = prompt("Invalid code. Please enter the confirmation code sent to your email: " + email);
            }
        },

        /**
         * Handle the form submission
         * 
         */
        handleSubmit() {
            console.log("Submit: ", this.login_mode);
            switch (this.login_mode) {
                // Sign in with an existing account
                case LOGIN_MODES.SIGN_IN:
                    authenticate(this.username, this.password).then(() => {
                        this.loginSuccess(this.username);
                    }).catch((error) => {
                        // If user is not confirmed, prompt for confirmation code
                        if (error.code === "UserNotConfirmedException") {
                            this.handleVerification(this.username, this.password);
                        } else {
                            alert("Login failed: " + error.message);
                        }
                    });
                    break;
                // Sign up for a new account and confirm the registration
                case LOGIN_MODES.SIGN_UP:
                    register(this.username, this.password).then(() => {
                        this.handleVerification(this.username, this.password);
                    }).catch((error) => {
                        alert("Registration failed: " + error.message);
                    });
                    break;
                case LOGIN_MODES.RESET_PASSWORD:
                    resetPassword(this.username, this.password).then(() => {
                        alert("Password reset successful. Please Login with your new password.");
                        this.setLoginMode(LOGIN_MODES.SIGN_IN);
                    }).catch((error) => {
                        alert("Password reset failed: " + error.message);
                    });
                    break;
                default:
                    break;
            }
        },

        /**
         * Set the login mode
         * 
         * @param {string} mode login mode
         */
        setLoginMode(mode) {
            this.login_mode = mode;
        },
    },
    computed: {
        // Expose constants to the template
        LOGIN_MODES() {
            return LOGIN_MODES;
        },
        STAND_QA_VERSION() {
            return STAND_QA_VERSION;
        }
    },
    beforeDestroy() {
        unloadVuetifyCSS();
    }
};
</script>

<style scoped>
div.sign-in-container {
    margin-top: 5%;
    width: 40%;
}

div.login-mode-container {
    padding: 10px;
}

div.login-mode-container button.login-mode-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
}
</style>