<template>
    <div :style="cssProps">
        <br/>
        <input type="button" v-on:click="save_grid_edits()" value="Save Changes"/>&nbsp;&nbsp;
        <p :style="{'display': 'inline-block'}">Grid Width:</p>&nbsp;
        <input
            v-model.number="GRID_WIDTH" 
            type="number"  
            v-on:change="changeGridWidth()"/>&nbsp;
        <p :style="{'display': 'inline-block'}">Image Size:</p>&nbsp;
        <input type="button" v-on:click="changeImageSize(1.1)" value="+"/>&nbsp;
        <input type="button" v-on:click="changeImageSize(1/1.1)" value="-"/>
        <br/>
        <input 
            type="button" 
            v-on:click="toggleApproved()" 
            :value="(this.hide_approved ? 'Show' : 'Hide') + ' Approved Images'"
        />&nbsp;
        <input 
            type="button" 
            v-on:click="toggleRejected()" 
            :value="(this.hide_rejected ? 'Show' : 'Hide') + ' Rejected Images'"
        />
        <br/>
        <br/>
        <p :style="{'display': 'inline-block'}">Number of Images to Keep:</p>&nbsp;
        <input
            v-model.number="AUTO_REJECT_NUM_IMGS"
            type="number"
        />&nbsp;
        <input 
            type="button" 
            v-on:click="autoRejectImages()" 
            value="Auto Reject Images"
        />
        <br/>
        <!-- show buffer pct -->
        <div>Buffer: {{ buffer_pct }}%</div>
        <div>Num Images: {{ num_images }}, Num Approved: {{ num_approved }}, Num Rejected: {{ num_rejected }}</div>
        <div id='grid-parent'>
            <table id='grid'>
                <tr v-for="row_imgs, grid_idx in grid_imgs" :key='grid_idx'>
                    <td v-for="img, col_idx in row_imgs" :key='col_idx' :class="[{grid_approved: cur_img_approved[img]}]">
                        <div 
                            :class="[{grid_rejected: row_qa_mode_arr[img] && cur_row_qa_rej[img]}, {grid_changed: row_qa_mode_arr[img] && (cur_row_qa_rej[img] != og_row_qa_rej[img]) }, 'img_holder']"
                            :id="img" 
                            :key="component_updater"
                            @mouseover="mark_hovered(img, true)"
                            @mouseleave="mark_hovered(img, false)"
                            @click="swap_row_rej(img)"
                        >                                    
                        
                            <img :id="img + '_img'" class="grid-img" :src="displayed_img_thumbnail_urls[img]"/>
                            <img :id="img + '_row_overlay'" :class="[{hidden: !row_qa_mode_arr[img]},'overlay']" :src="row_thumbnail_urls[img]"/>
                            <img :id="img + '_keypoint_overlay'" :class="[{hidden: !keypoint_mode_arr[img]},'overlay']" :src="displayed_keypoint_thumbnail_urls[img]"/>
                                
                            <table class="subimage_grid" v-if="!row_qa_mode_arr[img]" :style="subimage_grid_style">
                                <!-- Subimages 0 thru 8, cancerous indexing bc vue starts at 1 -->
                                <tr v-for="i in 3" :key="i">
                                    <td v-for="j in 3" :key="j"
                                        :class="[{grid_rejected: cur_all_rej[img][3*i+j-4]}, {grid_changed: cur_all_rej[img][3*i+j-4] != og_all_rej[img][3*i+j-4]}, 'subimage']"
                                        @click="swap_rej(img, 3*i+j-4)"
                                    ></td>
                                </tr>
                            </table>
                        </div>
                        <p> {{ img }} </p>
                        <table id="checkbox-grid" :key="component_updater">
                            <tr class="checkbox-labels">
                                <td :class="[{grid_changed: cur_img_approved[img] != og_img_approved[img]}]">
                                    Approve
                                </td>
                                <td>Go to Row QA</td>
                                <td :class="[{grid_changed: cur_needs_ppa_qa[img] != og_needs_ppa_qa[img]}]">
                                    Needs PPA QA
                                </td>
                                <td :class="[{grid_changed: cur_needs_row_qa[img] != og_needs_row_qa[img]}]">
                                    Needs Row QA
                                </td>
                                <td :class="[{grid_changed: cur_needs_gender_qa[img] != og_needs_gender_qa[img]}]">
                                    Needs Gender QA
                                </td>
                            </tr>
                            <tr>
                                <td :class="[{grid_changed: cur_img_approved[img] != og_img_approved[img]}]">
                                    <!-- Use @change to update the table -->
                                    <input 
                                        type="checkbox" 
                                        :id="img + '_approved_checkbox'" 
                                        v-model="cur_img_approved[img]" 
                                        :checked="cur_img_approved[img]"
                                        @change="component_updater++"
                                        :disabled="cur_row_qa_rej[img]"
                                    />
                                </td>
                                <td>
                                    <input type="button" value="🚀" v-on:click="launch_row_qa(img)"/>
                                </td>
                                <td :class="[{grid_changed: cur_needs_ppa_qa[img] != og_needs_ppa_qa[img]}]">
                                    <input 
                                        type="checkbox" 
                                        :id="img + '_ppa_checkbox'"
                                        v-model="cur_needs_ppa_qa[img]" 
                                        :checked="cur_needs_ppa_qa[img]"
                                        @change="component_updater++"
                                        :disabled="cur_img_approved[img] || cur_row_qa_rej[img]"
                                    />
                                </td>
                                <td :class="[{grid_changed: cur_needs_row_qa[img] != og_needs_row_qa[img]}]">
                                    <input 
                                        type="checkbox" 
                                        :id="img + '_row_checkbox'"
                                        v-model="cur_needs_row_qa[img]" 
                                        :checked="cur_needs_row_qa[img]"
                                        @change="component_updater++"
                                        :disabled="cur_img_approved[img] || cur_row_qa_rej[img]"
                                    />
                                </td>
                                <td :class="[{grid_changed: cur_needs_gender_qa[img] != og_needs_gender_qa[img]}]">
                                    <input 
                                        type="checkbox" 
                                        :id="img + '_gender_checkbox'"
                                        v-model="cur_needs_gender_qa[img]" 
                                        :checked="cur_needs_gender_qa[img]"
                                        @change="component_updater++"
                                        :disabled="cur_img_approved[img] || cur_row_qa_rej[img]"
                                    />
                                </td>
                            </tr>                  
                            
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { 
    auto_reject_images,
    batch_update_qa,
    get_all_field_tags,
    get_all_rgb_filenames,
    get_progress_json,
    get_urls_by_img_name_key
} from "../utils/api_endpoints.js"
import { launch_row_qa } from "../utils/nav_utils.js";
import $ from "jquery";

export default {
    data() {
        return {
            GRID_WIDTH: 1, // const
            AUTO_REJECT_NUM_IMGS: 50, // const
            image_height: 30,
            image_width: 40,
            field_json: "",
            progress_data: null,
            image_data: null,
            images: null,
            grid_imgs: [],
            og_all_rej: {}, 
            cur_all_rej: {}, 
            og_row_qa_rej: {},
            cur_row_qa_rej: {},
            og_img_approved: {},
            cur_img_approved: {},
            og_needs_ppa_qa: {},
            cur_needs_ppa_qa: {},
            og_needs_row_qa: {},
            cur_needs_row_qa: {},
            og_needs_gender_qa: {},
            cur_needs_gender_qa: {},
            component_updater: 0,
            hovered_img: null,
            rej_all_key: false,
            // Raw URLS
            rgb_thumbnail_urls: {},
            binary_thumbnail_urls: {},
            row_thumbnail_urls: {},
            all_keypoint_thumbnail_urls: {
                "yellow": {},
                "red": {},
                "pink": {},
            },
            // Currently displayed URLS
            displayed_img_thumbnail_urls: {},  // RGB, binary
            displayed_keypoint_thumbnail_urls: {},
            // Trackers for currently displayed images
            current_global_keypoint_color: "yellow",
            current_keypoint_color_by_img: {},
            current_img_type_by_img: {},
            row_qa_mode_arr: {}, // Track if image is in row_qa mode
            keypoint_mode_arr: {}, // Track if keypoints are showing
            // State flags for all images
            all_keypoints_on: false,
            all_rows_on: false,
            all_binaries_on: false,
            hide_approved: false,
            hide_rejected: false,
            field_tags: {},
            subimage_grid_style: {},
            buffer_pct: 0,
            num_images: null,
            num_approved: null,
            num_rejected: null
        }
    },
    async created() {
        this.field_json = this.$store.state.$field_json;
        let bin_folder = "binaries/binarygen_thresh_0.4"

        let binary_thumbnail_key = bin_folder + "_thumbnails";

        // Get all thumbnail urls
        let thumbnail_urls_by_img_key_params = {
            field_json: this.field_json,
            index_folder_name: "RGB_thumbnails",
            img_folder_names: [
                "row_thumbnails",
                "keypoint_thumbnails/PINK_thumbnails",
                "keypoint_thumbnails/RED_thumbnails",
                "keypoint_thumbnails/YELLOW_thumbnails",
                binary_thumbnail_key
            ],
            ext_override: {
                'row_thumbnails': '.PNG',
                'keypoint_thumbnails/PINK_thumbnails': '.PNG',
                'keypoint_thumbnails/RED_thumbnails': '.PNG',
                'keypoint_thumbnails/YELLOW_thumbnails': '.PNG',
                [binary_thumbnail_key]: '.png'
            }
        }
        let all_thumbnail_urls;
        // Group these with Promise.all
        [
            this.images, 
            this.progress_data,
            this.field_tags,
            all_thumbnail_urls
        ] = await Promise.all([
            get_all_rgb_filenames(this.field_json),
            get_progress_json(this.field_json),
            get_all_field_tags(this.field_json),
            get_urls_by_img_name_key(thumbnail_urls_by_img_key_params)
        ]);
        console.log(all_thumbnail_urls);
        
        // Distribute thumbnail urls to relevant objects
        this.rgb_thumbnail_urls = all_thumbnail_urls['RGB_thumbnails_urls'];
        this.binary_thumbnail_urls = all_thumbnail_urls[bin_folder + "_thumbnails_urls"];
        this.row_thumbnail_urls = all_thumbnail_urls['row_thumbnails_urls'];
        this.all_keypoint_thumbnail_urls["pink"] = all_thumbnail_urls['keypoint_thumbnails/PINK_thumbnails_urls'];
        this.all_keypoint_thumbnail_urls["red"] = all_thumbnail_urls['keypoint_thumbnails/RED_thumbnails_urls'];
        this.all_keypoint_thumbnail_urls["yellow"] = all_thumbnail_urls['keypoint_thumbnails/YELLOW_thumbnails_urls'];


        this.subimage_grid_style = {
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
        }
        try {
            this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            let remaining_pct = 100 - (this.buffer_pct * 2);
            let grid_offset = this.buffer_pct;
            this.subimage_grid_style = { 
                top: grid_offset + "%",
                left: grid_offset + "%",
                height: remaining_pct + "%",
                width: remaining_pct + "%",
            }
        }
        catch (e) {
            console.log(e)
        }

        // Default to yellow keypoints
        this.current_keypoint_thumbnail_urls = JSON.parse(JSON.stringify(this.all_keypoint_thumbnail_urls["yellow"]));
        this.image_data = this.progress_data["files"];

        let grid_counter = 0;
        let row_imgs = [];
        let cur_im = null;
        let all_rej = null;
        for (let i = 0; i < this.images.length; i++) {
            cur_im = this.images[i]
            
            // Start with rgb showing
            this.displayed_img_thumbnail_urls[cur_im] = this.rgb_thumbnail_urls[cur_im];
            this.displayed_keypoint_thumbnail_urls[cur_im] = this.all_keypoint_thumbnail_urls["yellow"][cur_im];
            this.current_img_type_by_img[cur_im] = "rgb";
            this.current_keypoint_color_by_img[cur_im] = "yellow";
            this.row_qa_mode_arr[cur_im] = false;
            this.keypoint_mode_arr[cur_im] = false;

            // Add all-rej markers 
            let image_progress = this.progress_data['files'][cur_im];
            all_rej = this.all_rejected(image_progress)
            this.og_all_rej[cur_im] = JSON.parse(JSON.stringify(all_rej));
            this.cur_all_rej[cur_im] = JSON.parse(JSON.stringify(all_rej));

            // Add row qa rejection markers
            this.og_row_qa_rej[cur_im] = image_progress["row_qa"] == "rejected";
            this.cur_row_qa_rej[cur_im] = image_progress["row_qa"] == "rejected";
            this.og_img_approved[cur_im] = this.isImageApproved(cur_im);
            this.cur_img_approved[cur_im] = this.isImageApproved(cur_im);
            this.og_needs_ppa_qa[cur_im] = image_progress["ppa_qa"] == "not_done";
            this.cur_needs_ppa_qa[cur_im] = image_progress["ppa_qa"] == "not_done";
            this.og_needs_row_qa[cur_im] = image_progress["row_qa"] == "not_done";
            this.cur_needs_row_qa[cur_im] = image_progress["row_qa"] == "not_done";
            this.og_needs_gender_qa[cur_im] = image_progress["gender_qa"] == "not_done";
            this.cur_needs_gender_qa[cur_im] = image_progress["gender_qa"] == "not_done";

            // Add to grid
            if (grid_counter >= this.GRID_WIDTH) {
                this.grid_imgs.push(row_imgs);
                grid_counter = 0;
                row_imgs = [];
            }
            grid_counter++;
            row_imgs.push(cur_im);
        }
        this.grid_imgs.push(row_imgs);

        this.update_progress_counter();

        window.addEventListener("scroll", () => {
            // Prevent weird behavior when scrolling
            this.hovered_img = null;
        }); 

        window.addEventListener("keydown", (e) => {
            switch(e.key) {
                case "s":
                    if (e.ctrlKey) {
                        e.preventDefault();
                        this.save_grid_edits();
                    }
                    break;
                case "b":
                    if (this.hovered_img !== null) {
                        this.swap_binary(this.hovered_img);
                    }
                    break;
                case "d":
                    this.reject_all(this.hovered_img);
                    break;
                case "r":
                case "k":
                    if (this.hovered_img !== null) {
                        this.change_overlay_mode_single_image(this.hovered_img, e.key);
                    }
                    break;
                case "R":
                case "K":
                    this.change_overlay_mode_all_images(e.key);
                    break;
                case "B":
                    console.log("swap all binaries")
                    this.swap_all_binaries();
                    return;
                case "=":  // Increase image size
                    this.changeImageSize(1.1);
                    return;
                case "-":  // Decrease image size
                    this.changeImageSize(1/1.1);
                    return;
                case "c":
                    this.change_keypoint_color(this.hovered_img);
                    return;
                case "C":
                    this.change_all_keypoint_colors();
                    return;
            }
        });

        window.addEventListener("keyup", (e) => {
            if (e.key == "Control") {
                this.rej_all_key = false;
            }
        });

        // this.setScroll();
    },
    async mounted() {

    },
    computed: {
        cssProps() {
            return {
                '--image-height': this.image_height + "em",
                '--image-width': this.image_width + "em",
            }
        },
    },
    methods: {
        setScroll() {
            console.log("Scroll: " + this.$store.state.$grid_scroll);
            $("html, body").animate({ scrollTop: this.$store.state.$grid_scroll });
        },
        saveScroll() {
            console.log($("html, body").scrollTop());
            this.$store.commit("update$grid_scroll", $("html, body").scrollTop());
        },
        toggleApproved() {
            // Change status and remake the grid
            this.hide_approved = !this.hide_approved;
            this.changeGridWidth();
        },
        toggleRejected() {
            // Change status and remake the grid
            this.hide_rejected = !this.hide_rejected;
            this.changeGridWidth();
        },
        isImageApproved(image_name) {
            let image_progress = this.progress_data["files"][image_name];
            return (
                image_progress["row_qa"] == "approved" &&
                image_progress["ppa_qa"] == "approved" &&
                image_progress["gender_qa"] == "approved"
            )
        },
        isImageRejected(image_name) {
            let image_progress = this.progress_data["files"][image_name];
            return (
                image_progress["row_qa"] == "rejected" ||
                image_progress["ppa_qa"] == "rejected" ||
                image_progress["gender_qa"] == "rejected"
            )
        },
        allSubimagesApproved(image_name) {
            let subimages = this.progress_data["files"][image_name]["subimages"];
            return subimages.every(subimage => {
                if (subimage["keypoint_qa"] === "approved") {
                    return true;
                }
            });
        },
        changeGridWidth() {
            let grid_counter = 0;
            let row_imgs = [];
            let cur_im = null;
            this.grid_imgs = []
            for (let i = 0; i < this.images.length; i++) {
                cur_im = this.images[i]
                
                // Don't include images marked as hidden
                if (this.hide_approved && this.cur_img_approved[cur_im]) {
                    continue
                } else if (this.hide_rejected && this.cur_row_qa_rej[cur_im]) {
                    continue
                }

                // Add to grid
                if (grid_counter >= this.GRID_WIDTH) {
                    this.grid_imgs.push(row_imgs);
                    grid_counter = 0;
                    row_imgs = [];
                }
                grid_counter++;
                row_imgs.push(cur_im);
            }
            this.grid_imgs.push(row_imgs);
        },
        async autoRejectImages() {
            alert("Auto Rejecting Images")
            await auto_reject_images(this.field_json, this.AUTO_REJECT_NUM_IMGS);
        },
        changeImageSize(val) {
            this.image_height = val*this.image_height;
            this.image_width = val*this.image_width;
        },
        /**
         * Get the next keypoint color, given the current color
         *
         * @param {String} current_color_str The current color, as a string
         * @returns {String} The next color, as a string
         */
        get_next_keypoint_color(current_color_str) {
            let all_color_strs = Object.keys(this.all_keypoint_thumbnail_urls);
            let current_color_idx = all_color_strs.indexOf(current_color_str);
            let new_color_idx = (current_color_idx + 1) % all_color_strs.length;
            return all_color_strs[new_color_idx];
        },
        /**
         * Change the displayed keypoint color for a single image. 
         * @param {String} image_name Image name
         * @param {String} color Color to change to 
         */
        change_displayed_keypoint_color(image_name, color) {
            let url_to_display = this.all_keypoint_thumbnail_urls[color][image_name];

            this.displayed_keypoint_thumbnail_urls[image_name] = url_to_display;
            document.getElementById(image_name + "_keypoint_overlay").src = url_to_display;

            this.current_keypoint_color_by_img[image_name] = color;
        },
        /**
         * Change the keypoint color for a single image to the next color.
         * @param {String} image_name Image name
         */
        change_image_to_next_keypoint_color(image_name) {
            let new_color = this.get_next_keypoint_color(
                this.current_keypoint_color_by_img[image_name]
            );
            this.change_displayed_keypoint_color(image_name, new_color);
        },
        /**
         * Change the keypoint color for all images to the next color.
         */
        change_all_keypoint_colors() {
            this.current_global_keypoint_color = this.get_next_keypoint_color(this.current_global_keypoint_color);

            // Switch all keypoints not already that color
            for (let image_name of this.images) {
                this.change_displayed_keypoint_color(
                    image_name,
                    this.current_global_keypoint_color
                );
            }
        },
        /**
         * Change the overlay mode for a single image. 
         * @param {String} image_name Image name 
         * @param {String} pressed_key Key pressed 
         */
        change_overlay_mode_single_image(image_name, pressed_key) {
            if (pressed_key == "r") { // Row overlay
                this.row_qa_mode_arr[image_name] = !this.row_qa_mode_arr[image_name];
            } else if (pressed_key == "k") { // Keypoint overlay
                this.keypoint_mode_arr[image_name] = !this.keypoint_mode_arr[image_name];
            }
            this.component_updater += 1;
        },
        /**
         * Change the overlay mode for all images. 
         * @param {String} pressed_key Key pressed 
         */
        change_overlay_mode_all_images(pressed_key) {
            if (pressed_key == "R") { // Row overlay
                this.all_rows_on = !this.all_rows_on;
                for (let image_name in this.row_qa_mode_arr) {
                    this.row_qa_mode_arr[image_name] = this.all_rows_on;
                }
            } else if (pressed_key == "K") { // Keypoint overlay
                this.all_keypoints_on = !this.all_keypoints_on;
                for (let image_name in this.keypoint_mode_arr) {
                    this.keypoint_mode_arr[image_name] = this.all_keypoints_on;
                }
            }
            this.component_updater += 1;
        },
        /**
         * Change the displayed image for a single grid entry.
         * 
         * @param {String} image_name Image name
         * @param {String} new_url New image url
         * @param {String} image_type Image type, for tracking
         */
        change_displayed_img(image_name, new_url, image_type) {
            // Change both the actual src and the grid_urls
            // bc changing the src looks cleaner and
            // changing the grid_urls prevents it from reverting
            this.current_img_type_by_img[image_name] = image_type;
            document.getElementById(image_name + "_img").src = new_url;
            this.displayed_img_thumbnail_urls[image_name] = new_url;
        },
        swap_rej(image, subimage_idx) {
            // Mark all as rejected when in reject all mode
            if (this.rej_all_key) {
                this.reject_all(image);
            } else {
                let rej_count = 0;
                let n_subimages = this.cur_all_rej[image].length
                for (let i = 0; i < n_subimages; i++) {
                    if (this.cur_all_rej[image][i]) {
                        rej_count++;
                    }
                }

                // Mark all as rejected when rejecting the last subimage
                let rejecting = !this.cur_all_rej[image][subimage_idx]
                if (rej_count == n_subimages-1 && rejecting) {
                    this.reject_all(image);
                } else {
                    this.cur_all_rej[image][subimage_idx] = !this.cur_all_rej[image][subimage_idx];
                    // Whenever un-rejecting a subimage, mark row qa as not rejected
                    if (!rejecting) {
                        this.cur_row_qa_rej[image] = false;
                    }
                }
                this.component_updater += 1;
            }
        },
        swap_row_rej(image_name) {
            if (this.row_qa_mode_arr[image_name]) {
                this.cur_row_qa_rej[image_name] = !this.cur_row_qa_rej[image_name];
                // When rejecting row qa, reject all subimages
                this.reject_all(image_name);
                this.component_updater += 1;
            }
        },
        all_rejected(image_progress) {
            // Return array of subimages and their rejection status
            let all_rej = []
            for (let i = 0; i < image_progress['subimages'].length; i++) {
                // TODO constants
                all_rej.push(image_progress['subimages'][i]['keypoint_qa'] == 'rejected');
            }
            return all_rej;
        },
        update_progress_counter() {
            this.num_images = this.images.length;
            this.num_approved = 0;
            this.num_rejected = 0;
            for (let i = 0; i < this.num_images; i++) {
                if (this.isImageApproved(this.images[i])) {
                    this.num_approved++;
                } else if (this.isImageRejected(this.images[i])) {
                    this.num_rejected++;
                }
            }
        },
        async save_grid_edits() {
            let update_params = {
                field_json: this.field_json,
                update_items: [],
            }
            let current_state = null;
            let og_state = null;
            let cur_img = null;
            let update_key = null;
            let n_subimages = null;
            for (let i = 0; i < this.images.length; i++) {
                cur_img = this.images[i];
                n_subimages = this.image_data[cur_img]['subimages'].length;

                // Approve the whole image if the approve button was checked by the user
                // but do nothing if the image wasn't approved
                current_state = this.cur_img_approved[cur_img];
                og_state = this.og_img_approved[cur_img];
                if (current_state && current_state != og_state) {
                    update_key = "approved";
                    
                    // Row QA
                    this.cur_needs_row_qa[cur_img] = false;
                    this.cur_row_qa_rej[cur_img] = false; 
                    if (update_key != this.image_data[cur_img]["row_qa"]) {
                        update_params['update_items'].push({
                            image_name: cur_img,
                            qa_type: "row_qa",
                            qa_val: update_key,
                        });
                        this.image_data[cur_img]["row_qa"] = update_key;
                    }

                    // PPA QA
                    this.cur_needs_ppa_qa[cur_img] = false;
                    if (update_key != this.image_data[cur_img]["ppa_qa"]) { 
                        update_params['update_items'].push({
                            image_name: cur_img,
                            qa_type: "ppa_qa",
                            qa_val: update_key,
                        });
                        this.image_data[cur_img]["ppa_qa"] = update_key;
                    }

                    // Gender QA
                    this.cur_needs_gender_qa[cur_img] = false;
                    if (update_key != this.image_data[cur_img]["gender_qa"]) { 
                        update_params['update_items'].push({
                            image_name: cur_img,
                            qa_type: "gender_qa",
                            qa_val: update_key,
                        });
                        this.image_data[cur_img]["gender_qa"] = update_key;
                    }

                    // Keypoint QA
                    for (let subimage_idx = 0; subimage_idx < n_subimages; subimage_idx++) {
                        // Only approve non-rejected subimages
                        if (this.cur_all_rej[cur_img][subimage_idx]) {
                            // Subimage rejected
                            if ("rejected" != this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"]) {
                                update_params['update_items'].push({
                                    image_name: cur_img,
                                    qa_type: "keypoint_qa",
                                    qa_val: "rejected",
                                    subimage_idx: subimage_idx,
                                });
                                this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"] = "rejected";
                            }
                        } else {
                            // Subimage not rejected
                            this.cur_all_rej[cur_img][subimage_idx] = false;
                            if (update_key != this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"]) {
                                update_params['update_items'].push({
                                    image_name: cur_img,
                                    qa_type: "keypoint_qa",
                                    qa_val: update_key,
                                    subimage_idx: subimage_idx,
                                });
                                this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"] = update_key;
                            }
                        }
                    }
                } else {
                    // Only update "needs ppa, row, gender" if row wasn't rejected
                    if (!this.cur_row_qa_rej[cur_img]) {
                        // Row QA 
                        current_state = this.cur_needs_row_qa[cur_img];
                        og_state = this.og_needs_row_qa[cur_img];
                        if (current_state != og_state) {
                            update_key = current_state ? "not_done" : "approved"
                            update_params['update_items'].push({
                                image_name: cur_img,
                                qa_type: "row_qa",
                                qa_val: update_key,
                            });
                            this.image_data[cur_img]["row_qa"] = update_key;
                        }

                        // PPA QA
                        current_state = this.cur_needs_ppa_qa[cur_img];
                        og_state = this.og_needs_ppa_qa[cur_img];
                        if (current_state != og_state) { 
                            update_key = current_state ? "not_done" : "approved"
                            if (update_key != this.image_data[cur_img]["ppa_qa"]) { 
                                update_params['update_items'].push({
                                    image_name: cur_img,
                                    qa_type: "ppa_qa",
                                    qa_val: update_key,
                                });
                                this.image_data[cur_img]["ppa_qa"] = update_key;
                            }
                            // Keypoint QA
                            for (let subimage_idx = 0; subimage_idx < n_subimages; subimage_idx++) {
                                // Prevent marking rejected subimages as not done
                                if (
                                    update_key != this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"] &&
                                    this.cur_all_rej[cur_img][subimage_idx] == this.og_all_rej[cur_img][subimage_idx] &&
                                    !this.cur_all_rej[cur_img][subimage_idx]
                                ) {
                                    update_params['update_items'].push({
                                        image_name: cur_img,
                                        qa_type: "keypoint_qa",
                                        qa_val: update_key,
                                        subimage_idx: subimage_idx,
                                    });
                                    this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"] = update_key;
                                }
                            } 
                        }

                        // Gender QA
                        current_state = this.cur_needs_gender_qa[cur_img];
                        og_state = this.og_needs_gender_qa[cur_img];
                        if (current_state != og_state) { 
                            update_key = current_state ? "not_done" : "approved"
                            update_params['update_items'].push({
                                image_name: cur_img,
                                qa_type: "gender_qa",
                                qa_val: update_key,
                            });
                            this.image_data[cur_img]["gender_qa"] = update_key;
                        }
                    }


                    // Subimage rejection
                    for (let subimage_idx = 0; subimage_idx < n_subimages; subimage_idx++) {
                        current_state = this.cur_all_rej[cur_img][subimage_idx];
                        og_state = this.og_all_rej[cur_img][subimage_idx];
                        if (current_state != og_state) {
                            update_key = current_state ? "rejected" : "not_done";
                            update_params['update_items'].push({
                                image_name: cur_img,
                                qa_type: "keypoint_qa",
                                qa_val: update_key,
                                subimage_idx: subimage_idx,
                            });
                            this.image_data[cur_img]["subimages"][subimage_idx]["keypoint_qa"] = update_key;
                        }
                    }

                    // Row qa mode
                    current_state = this.cur_row_qa_rej[cur_img];
                    og_state = this.og_row_qa_rej[cur_img];
                    if (current_state != og_state) {
                        let row_update_key = current_state ? "rejected" : "not_done";
                        // Update row qa
                        if (this.image_data[cur_img]["row_qa"] != row_update_key) {
                            update_params['update_items'].push({
                                image_name: cur_img,
                                qa_type: "row_qa",
                                qa_val: row_update_key,
                            });
                            this.image_data[cur_img]["row_qa"] = row_update_key;
                        }
                        
                        // When rejecting, also reject gender/ppa qa
                        if (current_state) {
                            if (this.image_data[cur_img]["gender_qa"] != row_update_key) {
                                update_params['update_items'].push({
                                    image_name: cur_img,
                                    qa_type: "gender_qa",
                                    qa_val: row_update_key,
                                });
                                this.image_data[cur_img]["gender_qa"] = row_update_key;
                            }
                            if (this.image_data[cur_img]["ppa_qa"] != row_update_key) {
                                update_params['update_items'].push({
                                    image_name: cur_img,
                                    qa_type: "ppa_qa",
                                    qa_val: row_update_key,
                                });
                                this.image_data[cur_img]["ppa_qa"] = row_update_key;
                            }
                        }
                        
                        // Update the checkboxes
                        this.cur_needs_ppa_qa[cur_img] = this.image_data[cur_img]["ppa_qa"] == "not_done"
                        this.cur_needs_row_qa[cur_img] = this.image_data[cur_img]["row_qa"] == "not_done"
                        this.cur_needs_gender_qa[cur_img] = this.image_data[cur_img]["gender_qa"] == "not_done"
                    }
                }
            }
            console.log(update_params);
            if (update_params.update_items.length > 0) {
                await batch_update_qa(update_params);
                // Assume save hits 
                for(var k = 0; k < this.images.length; k++) {
                    cur_img = this.images[k];
                    this.og_all_rej[cur_img] = JSON.parse(JSON.stringify(this.cur_all_rej[cur_img]));
                }
                this.og_row_qa_rej = JSON.parse(JSON.stringify(this.cur_row_qa_rej));
                this.og_img_approved = JSON.parse(JSON.stringify(this.cur_img_approved));
                this.og_needs_ppa_qa = JSON.parse(JSON.stringify(this.cur_needs_ppa_qa));
                this.og_needs_row_qa = JSON.parse(JSON.stringify(this.cur_needs_row_qa));
                this.og_needs_gender_qa = JSON.parse(JSON.stringify(this.cur_needs_gender_qa));
                this.component_updater++;
            }
            this.update_progress_counter();
        },

        launch_row_qa(image_name) {
            // this.saveScroll();
            launch_row_qa(this, image_name, true, false, true);
        },

        mark_hovered(image_name, mouseover) {
            if (mouseover) {
                this.hovered_img = image_name;
            } else {
                this.hovered_img = null;
            }
        },
        
        swap_binary(image_name) {
            if (this.current_img_type_by_img[image_name] != "binary") {
                this.change_displayed_img(image_name, this.binary_thumbnail_urls[image_name], "binary");
            } else {
                this.change_displayed_img(image_name, this.rgb_thumbnail_urls[image_name], "rgb");
            }
        },

        swap_all_binaries() {
            this.all_binaries_on = !this.all_binaries_on;
            for (let image_name of this.images) {
                if (this.all_binaries_on) {
                    this.change_displayed_img(image_name, this.binary_thumbnail_urls[image_name], "binary");
                } else {
                    this.change_displayed_img(image_name, this.rgb_thumbnail_urls[image_name], "rgb");
                }
            }
        },

        reject_all(image_name) {
            if (image_name !== null) {
                let rej_count = 0;
                for (let i = 0; i < this.cur_all_rej[image_name].length; i++) {
                    if (this.cur_all_rej[image_name][i]) {
                        rej_count++;
                    } else {
                        this.cur_all_rej[image_name][i] = true;
                    }
                }
                // Auto-reject row qa
                this.cur_row_qa_rej[image_name] = true;
            
                // If all are rejected already, switch to not rejected
                if (rej_count == this.cur_all_rej[image_name].length) {
                    for (let i = 0; i < this.cur_all_rej[image_name].length; i++) {
                        this.cur_all_rej[image_name][i] = false;
                    }
                    // Un-reject row qa
                    this.cur_row_qa_rej[image_name] = false;
                }

                this.component_updater += 1;
            }   
        },
    } 
}
</script>

<style>
.img_holder {
    position: relative; 
    width: fit-content;
    margin: auto;
}
.grid_changed {
    /* padding: 5px; */
    background-color: rgba(255, 255, 0, 0.5);
}
.grid_approved {
    background-color: rgba(81, 255, 0, 0.5);
}
.grid_rejected {
    position: relative;
    overflow: hidden; 
}
.grid_rejected:before, .grid_rejected:after {
    position: absolute;
    content: '';
    background: red;
    display: block;
    width: 80%;
    height: 15%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.grid_rejected:after {
    -webkit-transform: rotate(45deg);  
    transform: rotate(45deg); 
}

.grid-img {
    padding: 5px;
    width: var(--image-width);
    height: var(--image-height);
    border: 1px solid black;
    /* position: absolute; */
}
#grid-parent {
    display: flex;
    justify-content: center;
    align-items: center;    
}
.subimage_grid {
    position: absolute;
    /* top: 0;
    width: 100%;
    height: 100%; */
    z-index: 1;
}

.subimage {
    padding: 10px;
    border: 3px solid white;
    border-collapse: collapse;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: var(--image-width);
    height: var(--image-height);
    border: 1px solid black;
    /* z-index: 2; */
}

.hidden {
    display: none;
}

.checkbox-labels>td {
    border: 1px solid;
    padding: 0.1em 0.5em;
}

#checkbox-grid {
    margin-left: auto;
    margin-right: auto;
}

input[type=checkbox] {
    transform: scale(1.5);
}

</style>
