// Utilities for creating and modifiying ULabel annotations.
import { v4 } from 'uuid';

/**
 * Creates a classification payload for a subimage. 
 * @param {boolean} rejected Whether the subimage is rejected or not.
 * @returns The classification payload.
 */
export function get_subimage_class_payload(rejected = false) {
    return [
        {
            class_id: 21,
            confidence: rejected ? 0 : 1,
        },
        {
            class_id: 22,
            confidence: rejected ? 1 : 0,
        }
    ]
}

/**
 * Get the subimage crop given the subimage index, LRTB.
 * @param {Number} n_w_subimages Number of subimages width-wise.
 * @param {Number} n_h_subimages Number of subimages height-wise.
 * @param {Number} image_width Image width.
 * @param {Number} image_height Image height.
 * @param {Number} subimage_idx Subimage index.
 * @param {Number} buffer_pct optional: percentage of the subimage width/height to buffer the crop by, default 0.
 * @param {Number} resize_factor optional: multiplied by the subimage width/height to resize it, default 1.
 * @returns {Array} The subimage crop: [Top Left X, Top Left Y, Width, Height]
 */
export function get_subimage_crop_from_idx(
    n_w_subimages,
    n_h_subimages,
    image_width,
    image_height,
    subimage_idx,
    buffer_pct = 0,
    resize_factor = 1,
) {
    let buffer_float = buffer_pct / 100;
    let buffer_x_px = image_width * buffer_float;
    let buffer_y_px = image_height * buffer_float;

    let subimage_width = (image_width - (buffer_x_px * 2)) / n_w_subimages;
    let subimage_height = (image_height - (buffer_y_px * 2)) / n_h_subimages;

    let tl_x = ((subimage_idx % n_w_subimages) * subimage_width) + buffer_x_px;
    let tl_y = (Math.floor(subimage_idx / n_w_subimages) * subimage_height) + buffer_y_px;

    tl_x += (subimage_width * (1 - resize_factor)) / 2;
    tl_y += (subimage_height * (1 - resize_factor)) / 2;
    subimage_width *= resize_factor;
    subimage_height *= resize_factor;

    return [
        tl_x,
        tl_y,
        subimage_width,
        subimage_height,
    ]
}

/**
 * Get ULabel initial crop Object from bbox. 
 * @param {Number} tl_x Top left x coordinate.
 * @param {Number} tl_y Top left y coordinate.
 * @param {Number} width Annotation width.
 * @param {Number} height Annotation height.
 * @returns {Object} The initial crop Object, in ULabel format.
 */
export function get_ulabel_initial_crop_from_bbox(
    tl_x,
    tl_y,
    width,
    height,
) {
    return {
        top: tl_y,
        left: tl_x,
        height: height,
        width: width,
    }
}

/**
 * Creates a bbox annotation. 
 * @param {*} tl_x Top left x coordinate.
 * @param {*} tl_y Top left y coordinate.
 * @param {*} width Annotation width.
 * @param {*} height Annotation height.
 * @param {*} class_payload Classification payload.
 * @returns The bbox annotation, in ULabel format.
 */
export function make_ulabel_bbox(
    tl_x,
    tl_y,
    width,
    height,
    class_payload = null,
) { 
    if (class_payload === null) { 
        class_payload = get_subimage_class_payload();
    }
    return { 
        id: v4(),
        new: true,
        parent: null,
        created_by: "Pepsi Man",
        created_at: Date.now(),
        deprecated: false,
        spatial_type: "polygon",
        spatial_payload: [ 
            [ tl_x, tl_y ],
            [ tl_x + width, tl_y ],
            [ tl_x + width, tl_y + height ],
            [ tl_x, tl_y + height ],
            [ tl_x, tl_y ]
        ],
        classification_payloads: class_payload,
        line_size: 5,
        frame: null,
        text_payload: "",
        annotation_meta: null
    }
}

/**
 * Creates a list of bbox annotations for all subimages in an image, optionally with QA status.
 * @param {Number} n_w_subimages number of subimages width-wise.
 * @param {Number} n_h_subimages number of subimages height-wise.
 * @param {Number} image_width image width.
 * @param {Number} image_height image height.
 * @param {Object} qa_status optional: qa status for each subimage, default null.
 * @param {Number} buffer_pct optional: percentage of the subimage width/height to buffer the crop by, default 0.
 * @param {Number} resize_factor optional: multiplied by the subimage width/height to resize it, default 1.
 * @returns 
 */
export function make_all_subimage_bboxes(
    n_w_subimages,
    n_h_subimages,
    image_width,
    image_height,
    qa_status = null,
    buffer_pct = 0,
    resize_factor = 1
) { 
    let n_subimages = n_w_subimages * n_h_subimages;
    let bboxes = [];
    for (let subimage_idx = 0; subimage_idx < n_subimages; subimage_idx++) { 
        let class_payload = get_subimage_class_payload();
        if (qa_status !== null && qa_status[subimage_idx]["keypoint_qa"] === "rejected") {
            class_payload = get_subimage_class_payload(true);
        }
        let crop = get_subimage_crop_from_idx(
            n_w_subimages,
            n_h_subimages,
            image_width,
            image_height,
            subimage_idx,
            buffer_pct,
            resize_factor
        );
        let bbox = make_ulabel_bbox(
            ...crop,
            class_payload
        );
        bboxes.push(bbox);
    }
    return bboxes;
}

/**
 * Make ULabel polyline annotation from spatial and classification payloads. Optional line size.
 * @param {Object} spatial_payload Spatial payload.
 * @param {Object} classification_payload Classification payload.
 * @param {Number} line_size optional: line size, default 5.
 * @returns 
 */
function make_ulabel_polyline_from_spatial_and_classification_payloads(
    spatial_payload,
    classification_payload,
    line_size = 5,
) { 
    return { 
        id: v4(),
        new: true,
        parent: null,
        created_by: "Shaquile Oatmeal",
        created_at: Date.now(),
        deprecated: false,
        spatial_type: "polyline",
        spatial_payload: spatial_payload,
        classification_payloads: classification_payload,
        line_size: line_size,
        frame: null,
        text_payload: "",
        annotation_meta: null
    }
}

/**
 * Make ULabel subimage bboxes with a large x if rejected.
 * @param {Number} n_w_subimages number of subimages width-wise.
 * @param {Number} n_h_subimages number of subimages height-wise.
 * @param {Number} image_width image width.
 * @param {Number} image_height image height.
 * @param {Object} qa_status optional: qa status for each subimage, default null.
 * @param {Number} buffer_pct optional: percentage of the subimage width/height to buffer the crop by, default 0.
 * @param {Number} resize_factor optional: multiplied by the subimage width/height to resize it, default 1.
 * @param {Number} rejection_x_line_size optional: line size for the rejection x, default 50.
 * @param {Boolean} include_non_rejected_subimages optional: whether to include non-rejected subimages, default false.
 * @returns 
 */
export function make_all_subimages_bboxes_with_large_rejection_x(
    n_w_subimages,
    n_h_subimages,
    image_width,
    image_height,
    qa_status = null,
    buffer_pct = 0,
    resize_factor = 1,
    rejection_x_line_size = 50,
    include_non_rejected_subimages = false,
) {
    let og_subimage_annotations = make_all_subimage_bboxes(
        n_w_subimages,
        n_h_subimages,
        image_width,
        image_height,
        qa_status,
        buffer_pct,
        resize_factor
    );

    let ret_annos = [];
    for (let subimage_idx = 0; subimage_idx < og_subimage_annotations.length; subimage_idx++) {
        let current_subimage_anno = og_subimage_annotations[subimage_idx];
        if (qa_status !== null && qa_status[subimage_idx]["keypoint_qa"] === "rejected") {
            // Add a large rejection x
            let subimage_crop = current_subimage_anno.spatial_payload;
            let spatial_payload_a = [ 
                [ subimage_crop[0][0], subimage_crop[0][1] ],
                [ subimage_crop[2][0], subimage_crop[2][1] ],
            ]
            let spatial_payload_b = [ 
                [ subimage_crop[1][0], subimage_crop[1][1] ],
                [ subimage_crop[3][0], subimage_crop[3][1] ],
            ]
            ret_annos.push(
                make_ulabel_polyline_from_spatial_and_classification_payloads(
                    spatial_payload_a,
                    current_subimage_anno.classification_payloads,
                    rejection_x_line_size
                )
            )
            ret_annos.push(
                make_ulabel_polyline_from_spatial_and_classification_payloads(
                    spatial_payload_b,
                    current_subimage_anno.classification_payloads,
                    rejection_x_line_size
                )
            )
        }
        else if (include_non_rejected_subimages) { 
            ret_annos.push(current_subimage_anno);
        }
    }
    return ret_annos;
}