<template>
    <div class="RowQA">
        <div id="image-info" class="header-info-container">
            <h2
                :title="field_name + ' / ' + image_name"
                style="flex: 7;"
            >{{ this.trimText(field_name) }} / {{ this.trimText(image_name) }}</h2>
            <h2>{{ gender_qa ? "Gender QA" : "Row QA" }}</h2>
        </div>
        <div id="container" class="ulabel-container"/>
    </div>
</template>
<script>
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    set_slider_values_in_ulabel_html,
    subimage_bounds_subtask 
} from "../utils/ulabel_utils.js";
import { 
    get_all_field_tags,
    get_binary_url,
    get_next_image_to_qa,
    get_single_image_tags, 
    reject_qa,
    save_ppa_qa,
    save_row_qa, 
} from "../utils/api_endpoints.js";
import { ULABEL_SUBTASK_IDS } from "../utils/constants.js";
import { ULabel } from "ulabel"
import { extend_line, extend_all }  from "../utils/ulabel_extend_line";
import { get_image_and_annotations, getMeta } from "../utils/annotation_loaders.js";
import { lock_image, unlock_image, get_username } from "../utils/qa_utils.js";
import { get_subimage_qa_status } from "../utils/ddb_utils.js";
import { make_all_subimage_bboxes } from "../utils/ulabel_anno_utils.js";
import { hide_navbar, show_navbar } from "../utils/nav_utils";
import { trimText } from "../utils/display";

export default {
    data: function () {
        return {
            field_name: "",
            image_name: "",
            ulabel: null,
            rgb_image_url: "",
            annotations: "",
            keypoint_annotations: "", 
            binary_image_url: "", 
            rgb_displayed: true,
            field_json: null,
            leave_qa_cycle: false,
            image_tags: "",
            gender_qa: false,
            grid: false, // Whether to redirect to grid
            n_h_subimages: 3,
            n_w_subimages: 3,
            n_subimages: 9,
            image_height: 0,
            image_width: 0,
            opened_from_grid_as_new_tab: false
        };
    },
    async mounted() {
        // Hide the entire navbar to force using the buttons
        hide_navbar();

        // Add event listeners
        document.addEventListener("keydown", this.keydownEventHandler);

        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        
        let next_image_found = true;
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "gender_qa")) {
            this.gender_qa = this.$route.params.gender_qa;
        } else if (Object.prototype.hasOwnProperty.call(this.$route.query, "grid") && this.$route.query.grid == "true") {
            this.opened_from_grid_as_new_tab = true;
            // Copy relevant data from query to params
            this.$route.params.image_name = this.$route.query.image_name;
            this.$route.params.grid = this.$route.query.grid;
            this.$route.params.gender_qa = this.$route.query.gender_qa;
        }  
        
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) { 
            this.image_name = this.$route.params.image_name;
        } else {  
            // If no image_name is in the route params, we get the next available
            next_image_found = await this.load_next_image();
        }
        
        if (next_image_found) {
            this.load_and_run_all();
        }
    },
    methods: {
        trimText,
        keydownEventHandler(e) {
            extend_line(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            extend_all(e, this.ulabel, ULABEL_SUBTASK_IDS.ROW_QA);
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.binary_image_url);
                } else {
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
            if (e.key == "Enter") {
                document.getElementById("approve").click();
            }
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Un-hide the navbar
            show_navbar();
        },
        navigate_away() {
            this.beforeDestoryHandler();
            
            if (this.opened_from_grid_as_new_tab) {
                // Close new tab, no need to navigate
                return window.close();
            }
 
            this.$router.push({
                name: "Progress",
            });
        },

        /**
         * Load the next image to QA
         * 
         * @returns {Promise<boolean>} Whether the next image was loaded successfully
         */
        async load_next_image() {
            // If no image_name is in the route params, we get the next available
            let next_image = await get_next_image_to_qa(
                this.field_json,
                "",
                this.gender_qa ? "gender_qa" : "row_qa",
            )
            
            console.log(next_image)
            if (next_image == "Nothing to QA.") {
                this.navigate_away();
                return false;
            }
            this.image_name = next_image.image_name;
            return true;
        },
        async load_and_run_all(reload_ulabel = false) {
            // Start async tasks
            let lock_acquired_promise = lock_image(this.field_json, this.image_name);
            let image_tags_promise = get_single_image_tags(this.field_json, this.image_name);
            let field_tags_promise = get_all_field_tags(this.field_json);
            let subimage_statuses_promise = get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
           
            if (!await lock_acquired_promise) {
                alert("Image is locked by another user. Please try again later.");
                this.navigate_away();
            }

            // Start loading image and annotations
            let image_and_annotations_promise = this.get_image_and_row_annotations();

            this.field_tags = await field_tags_promise;
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            } catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct)

            // Store values in case of reject
            this.$store.commit("update$image_name", this.image_name);
            this.$store.commit("update$gender_qa", this.gender_qa);
            
            // Get subimage status to properly color subimages
            let data = await subimage_statuses_promise;
            this.subimage_qa_status = data["subimages"];
            this.image_tags = await image_tags_promise;

            // Finish loading image and annotations
            await image_and_annotations_promise;

            if (reload_ulabel) {
                // If ULabel is already running, just reload with new data
                await this.reload_ulabel();
            } else {
                // Start ULabel
                this.start_ULabel();
            }
        },
        async get_image_and_row_annotations() {
            // Start async tasks
            // Row annotations
            let params = {
                field_name: this.field_name,
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "row_annotations"
            }
            let row_annotations_promise = get_image_and_annotations(params);
            // Keypoint annotations
            params.annotation_dir = "annotations";
            let keypoint_annotations_promise = get_image_and_annotations(params);

            // Binary url
            let binary_image_url_promise = get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
            
            // Collect all promises
            // Row annotations
            let ret = await row_annotations_promise;
            this.rgb_image_url = ret[0];
            this.annotations = ret[1];
            // Keypoint annotations
            ret = await keypoint_annotations_promise;
            this.keypoint_annotations = ret[1];
            // Binary url
            this.binary_image_url = await binary_image_url_promise;

            // Image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;
        },
        async reload_ulabel() {
            // Set new image
            this.ulabel.swap_frame_image(this.rgb_image_url);
            this.rgb_displayed = true;
            // Set new annotations
            this.ulabel.set_annotations(this.keypoint_annotations, ULABEL_SUBTASK_IDS.PLANT_COUNT_QA);
            this.ulabel.set_annotations(this.annotations, ULABEL_SUBTASK_IDS.ROW_QA);
            this.ulabel.set_annotations(this.getSubimageBoundsSubtask()["resume_from"], ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS);
            set_slider_values_in_ulabel_html(this.image_tags, this.field_tags);
        },
        async on_submit(annotations) {
            console.log(annotations);
            let post_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"][ULABEL_SUBTASK_IDS.ROW_QA]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json, 
                reject: false,
                qa_type: this.gender_qa ? "gender_qa" : "row_qa"
            }
            let row_qa_promise = save_row_qa(post_annotations_params);

            let slider_values = get_slider_values_from_ulabel_html();
            post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"][ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]],
                    },
                },
                image_name: this.image_name,
                field_json: this.field_json, 
                subimage_idx: -1,
                keypoint_slider: slider_values.keypoint_confidence_slider_val,
                row_distance_slider: slider_values.row_dist_slider_val,
            };

            // Finish saving Row QA before saving PPA QA
            await row_qa_promise;
            await save_ppa_qa(post_annotations_params);

            // If everything occurred as expected, unlock 
            await unlock_image(this.field_json, this.image_name);

            if (this.leave_qa_cycle || this.opened_from_grid_as_new_tab) {
                // Leave QA cycle
                return this.navigate_away();
            }
            
            // Continue to next image
            if (await this.load_next_image()) {
                await this.load_and_run_all(true);
            }
        },
        async exitWithoutSaving() {
            // Unlock image and navigate away
            await unlock_image(this.field_json, this.image_name);
            this.navigate_away();
        },
        async rejectAndContinue() {
            // Reject
            await reject_qa(
                this.field_json, 
                this.image_name, 
                this.gender_qa ? "gender_qa" : "row_qa", 
            );

            // Unlock
            await unlock_image(this.field_json, this.image_name);

            // Navigate to next image
            await this.load_next_image();
            await this.load_and_run_all(true);
        },
        getSubmitButtons() {
            let args = {
                "set_saved": true,
                "size_factor": 0.8,
                "row_number": 0,
            }
            return [
                {
                    "name": "Approve",
                    "hook": this.on_submit,
                    "color": "lightgreen",
                    ...args,
                },
                {
                    "name": "Reject",
                    "hook": this.rejectAndContinue,
                    "color": "pink",
                    ...args,
                },
                {
                    "name": "Exit",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    ...args,
                },
            ];
        },
        getSubimageBoundsSubtask() {
            let subimage_bbox_annos = make_all_subimage_bboxes(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_qa_status,
                this.buffer_pct,
                0.995, // resize_factor
            )
            return subimage_bounds_subtask(subimage_bbox_annos, {inactive_opacity: 0.5});
        },
        start_ULabel() {
            let annos = this.annotations;
            let subtasks = {
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(this.keypoint_annotations),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: this.getSubimageBoundsSubtask(),
            };

            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }
            
            // Initial ULabel configuration
            const config = get_config_data(
                "container",
                this.rgb_image_url,
                email,
                subtasks,
                this.getSubmitButtons(),
                this.image_tags,
                this.field_tags,
            );
            let ulabel = new ULabel(config);
            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                );
            });
        },
    },
};
</script>
