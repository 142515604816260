<template>
    <div>
        <h2>Stand QA v{{ STAND_QA_VERSION }} </h2>
        <a
            :href="'//sentera.atlassian.net/wiki/spaces/SPO/pages/3047489599/Stand+QA+tool+Keybinds'" 
            target="_blank"
        >
            Useful Keybinds
        </a>
    </div>
</template>

<script>
import { STAND_QA_VERSION } from '../version';

export default {
    name: 'Home',

    computed: {
        // Expose VERSION to the template
        STAND_QA_VERSION() {
            return STAND_QA_VERSION;
        },
    },
}

</script>

<style>
</style>
