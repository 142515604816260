<template>
<div id=progress>
    <br/>
    <h2>Progress</h2>
    <p>Field Name: {{ field_name }}</p>
    <p>{{ Object.keys(image_data).length }} Images</p>
    <input type="button" v-on:click="modify_progress_json()" value="Save Changes" />
    <input type="button" v-on:click="unlock_all_locks()" value="Unlock All" />
    <br/>
    <br/>
    <!-- New Progress Table -->
    <table class="mainprogresstable">
        <input type="button" class="change-all-ppa-btn" v-on:click="change_all_status('ppa_qa')" value="Change All PPA QA" />
        <input type="button" class="change-all-row-btn" v-on:click="change_all_status('row_qa')" value="Change All Row QA" />
        <input type="button" class="not-done-ppa-btn" v-on:click="mark_all_not_done('ppa_qa')" value="Mark All 'Not Done' PPA QA" />
        <input type="button" class="not-done-row-btn" v-on:click="mark_all_not_done('row_qa')" value="Mark All 'Not Done' Row QA" />
        <thead>
            <tr>
                <th>Image ID</th>
                <th>File Lock</th>
                <th>Image Name</th>
            </tr>
        </thead>
        <tbody v-for="([image_name, image], id) in image_data_arr" :key="id" :class="[
                    {
                        allcomplete:
                            image.ppa_qa == 'approved' &&
                            image.row_qa == 'approved' &&
                            image.gender_qa == 'approved',
                        allrejected:
                            image.ppa_qa == 'rejected' &&
                            image.row_qa == 'rejected' &&
                            image.gender_qa == 'rejected',
                        incomplete:
                            image.ppa_qa == 'not_done' ||
                            image.ppa_qa == 'not_done' ||
                            image.gender_qa == 'not_done',
                    },
                    heavybordertopsides,
                ]">
            <tr class="noborder">
                <th class="noborder"></th>
                <th class="noborder"></th>
                <th class="noborder"></th>
            </tr>
            <tr>
                <td>
                    <b class="thick">{{ id }}</b>
                </td>

                <td style="text-align: center" :id="image_name + '_lock_cell'">
                    <input type="button" v-if="image.IS_LOCKED == false" :id="image_name + '_lock'" v-on:click="change_lock(image_name)" :value="symbols.unlocked" data-locked_status="ff" 
                        v-b-tooltip.hover :title=" is_modified_stream[image_name] "
                    />
                    <input type="button" v-else-if="image.IS_LOCKED" :id="image_name + '_lock'" v-on:click="change_lock(image_name)" :value="symbols.locked" data-locked_status="tt" class="locked"
                        v-b-tooltip.hover :title=" is_modified_stream[image_name] "
                    />
                    <b class="thick" v-if="image.IS_LOCKED"> {{ truncate_username(image.locked_by) }} </b>
                </td>
                <td>
                    <b class="thick">{{ image_name }}</b>
                </td>
            </tr>
            <tr class="heavyborderbottomsides">
                <td colspan="3">
                    <table align="center" style="padding: 0px; border: 0px">
                        <tr>
                            <td style="border: 0px">
                                <table :class="[
                                            {
                                                complete:
                                                    image.ppa_qa == 'approved',
                                                rejected:
                                                    image.ppa_qa == 'rejected',
                                                incomplete:
                                                    image.ppa_qa == 'not_done',
                                            },
                                            subimagetable,
                                        ]">
                                    <tr>
                                        <td colspan="2">
                                            <b class="thick">PPA QA</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="button" value="🚀" v-on:click="
                                                        launch_ppa_qa(
                                                            image_name,
                                                            0
                                                        )
                                                    " />
                                        </td>
                                        <td class="button_td" :id="
                                                    image_name + '_ppa_qa_cell'
                                                ">
                                            <input type="button" v-if="
                                                        image.ppa_qa ==
                                                        'not_done'
                                                    " :value="symbols.qa_not_done" data-qa_status="nn" :id="image_name + '_ppa_qa'" v-on:click="
                                                        change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )
                                                    " />
                                            <input type="button" v-else-if="
                                                        image.ppa_qa ==
                                                        'rejected'
                                                    " :value="symbols.qa_rejected" data-qa_status="rr" :id="image_name + '_ppa_qa'" v-on:click="
                                                        change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )
                                                    " />
                                            <input type="button" v-else-if="
                                                        image.ppa_qa ==
                                                        'approved'
                                                    " :value="symbols.qa_approved" data-qa_status="aa" :id="image_name + '_ppa_qa'" v-on:click="
                                                        change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )
                                                    " />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="border: 0px; width: 80%">
                                <table class="subimagetable" style="
                                            margin: 0px;
                                            border: 0px;
                                            padding: 0px;
                                        ">
                                    <td v-for="(
                                                subimage, subimage_idx
                                            ) in image['subimages']" :key="subimage_idx" style="
                                                border: 0px;
                                                margin: 0px;
                                                padding: 0px;
                                            ">
                                        <table class="subimagetable">
                                            <tbody :class="[
                                                        {
                                                            complete:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'approved',
                                                            rejected:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'rejected',
                                                            incomplete:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'not_done',
                                                        },
                                                    ]">
                                                <tr>
                                                    <td>
                                                        <b class="thick">{{
                                                                subimage_idx + 1
                                                            }}</b>
                                                    </td>
                                                    <td class="button_td" :id="
                                                                image_name +
                                                                '_keypoint_qa' +
                                                                subimage_idx +
                                                                '_cell'
                                                            ">
                                                        <input type="button" v-if="
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'not_done'
                                                                " :value="
                                                                    symbols.qa_not_done
                                                                " data-qa_status="nn" :id="
                                                                    image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx
                                                                " v-on:click="
                                                                    change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )
                                                                " />
                                                        <input type="button" v-else-if="
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'approved'
                                                                " :value="
                                                                    symbols.qa_approved
                                                                " data-qa_status="aa" :id="
                                                                    image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx
                                                                " v-on:click="
                                                                    change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )
                                                                " />
                                                        <input type="button" v-else-if="
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'rejected'
                                                                " :value="
                                                                    symbols.qa_rejected
                                                                " data-qa_status="rr" :id="
                                                                    image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx
                                                                " v-on:click="
                                                                    change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )
                                                                " />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <input type="button" value="🚀" v-on:click="
                                                                    launch_ppa_qa(
                                                                        image_name,
                                                                        subimage_idx
                                                                    )
                                                                " />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </table>
                            </td>
                            <td style="border: 0px">
                                <table :class="[
                                            {
                                                complete:
                                                    image.row_qa == 'approved',
                                                rejected:
                                                    image.row_qa == 'rejected',
                                                incomplete:
                                                    image.row_qa == 'not_done',
                                            },
                                            subimagetable,
                                        ]">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <b class="thick">Row QA</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="button" value="🚀" v-on:click="
                                                            launch_row_qa(
                                                                image_name,
                                                                false
                                                            )
                                                        " />
                                            </td>
                                            <td class="button_td" :id="
                                                        image_name +
                                                        '_row_qa_cell'
                                                    ">
                                                <input type="button" v-if="
                                                            image.row_qa ==
                                                            'not_done'
                                                        " :value="
                                                            symbols.qa_not_done
                                                        " data-qa_status="nn" :id="
                                                            image_name +
                                                            '_row_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )
                                                        " />
                                                <input type="button" v-else-if="
                                                            image.row_qa ==
                                                            'rejected'
                                                        " :value="
                                                            symbols.qa_rejected
                                                        " data-qa_status="rr" :id="
                                                            image_name +
                                                            '_row_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )
                                                        " />
                                                <input type="button" v-else-if="
                                                            image.row_qa ==
                                                            'approved'
                                                        " :value="
                                                            symbols.qa_approved
                                                        " data-qa_status="aa" :id="
                                                            image_name +
                                                            '_row_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )
                                                        " />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="border: 0px">
                                <table :class="[
                                            {
                                                complete:
                                                    image.gender_qa == 'approved',
                                                rejected:
                                                    image.gender_qa == 'rejected',
                                                incomplete:
                                                    image.gender_qa == 'not_done',
                                            },
                                            subimagetable,
                                        ]">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <b class="thick">Gender QA</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="button" value="🚀" v-on:click="
                                                            launch_row_qa(
                                                                image_name,
                                                                true,
                                                            )
                                                        " />
                                            </td>
                                            <td class="button_td" :id="
                                                        image_name +
                                                        '_gender_qa_cell'
                                                    ">
                                                <input type="button" v-if="
                                                            image.gender_qa ==
                                                            'not_done'
                                                        " :value="
                                                            symbols.qa_not_done
                                                        " data-qa_status="nn" :id="
                                                            image_name +
                                                            '_gender_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )
                                                        " />
                                                <input type="button" v-else-if="
                                                            image.gender_qa ==
                                                            'rejected'
                                                        " :value="
                                                            symbols.qa_rejected
                                                        " data-qa_status="rr" :id="
                                                            image_name +
                                                            '_gender_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )
                                                        " />
                                                <input type="button" v-else-if="
                                                            image.gender_qa ==
                                                            'approved'
                                                        " :value="
                                                            symbols.qa_approved
                                                        " data-qa_status="aa" :id="
                                                            image_name +
                                                            '_gender_qa'
                                                        " v-on:click="
                                                            change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )
                                                        " />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import $ from "jquery";
import { subimagetable, heavybordertopsides } from '../assets/styles/progress_table.css'
import { truncate_username } from "../utils/qa_utils.js";
import { batch_update_qa, get_all_image_tags, get_progress_json } from "../utils/api_endpoints.js";
import { launch_ppa_qa, launch_row_qa } from "../utils/nav_utils.js";
export default {
    data: function () {
        return {
            message: "",
            progress_json: "",
            field_name: "",
            image_data: "",
            image_data_arr: [],
            symbols: {
                unlocked: "\u{1F513}",
                locked: "\u{1F512}",
                qa_not_done: "\u2753",
                qa_approved: "\u2705",
                qa_rejected: "\u274C",
            },
            truncate_username: truncate_username,
            // TODO come up with better solution 
            subimagetable: subimagetable,
            heavybordertopsides: heavybordertopsides,
            image_tags: {},
            is_modified_stream: {},

        };
    },
    created() {
        window.addEventListener("keydown", (e) => {
            if (e.ctrlKey && e.key == "s") {
                e.preventDefault();
                this.modify_progress_json();
            }
        });
    },
    async mounted() {
        await this.getProgressData();
        this.setScroll();
    },
    methods: {
        setScroll() {
            console.log("Scroll: " + this.$store.state.$progress_scroll);
            $("html, body").animate({ scrollTop: this.$store.state.$progress_scroll });
        },
        saveScroll() {
            console.log($("html, body").scrollTop());
            this.$store.commit("update$progress_scroll", $("html, body").scrollTop());
        },
        async getProgressData() {
            this.field_name = this.$store.state.$field_name;
            this.field_json = this.$store.state.$field_json;
            let re = / /gi;
            this.field_name = this.field_name.replace(re, "_");
            this.image_tags = await get_all_image_tags(this.field_json);
            this.progress_json = await get_progress_json(this.field_json);
            this.image_data = this.progress_json["files"];
            this.image_data_arr = [];
            for (var key in this.image_data) {
                this.image_data_arr.push([key, this.image_data[key]]);

                // Get modified stream as a string for each image
                if (key in this.image_tags && "is_modified_by" in this.image_tags[key]) {
                    let modified_list = this.image_tags[key]["is_modified_by"];
                    let modified_str = "";
                    for (let idx in modified_list) {
                        modified_str += truncate_username(modified_list[idx]);
                        if (parseInt(idx) + 1 != modified_list.length) {
                            modified_str += ", ";
                        }
                    }
                    this.is_modified_stream[key] = modified_str;
                } else {
                    this.is_modified_stream[key] = "Not Modified";
                }
            }
            this.image_data_arr.sort((a, b) => {
                return a[0].localeCompare(b[0]);
            });
        },

        /**
         * Launch a PPA QA for a given image/subimage combo
         */
        launch_ppa_qa(image_name, subimage_idx) {
            this.saveScroll();
            launch_ppa_qa(this, image_name, subimage_idx);
        },

        /**
         * Launch a Row QA for a given image
         */
        launch_row_qa(image_name, gender_qa=false) {
            this.saveScroll();
            launch_row_qa(this, image_name, false, gender_qa);
        },

        /**
         * Change lock button between locked/unlocked
         * First character is current state
         * Second character is OG state
         */
        change_lock(image_name) {
            var lock_button = document.getElementById(image_name + "_lock");
            var lock_table_cell = document.getElementById(
                image_name + "_lock_cell"
            );
            var og_state = lock_button.getAttribute("data-locked_status")[1];
            var current_state =
                lock_button.getAttribute("data-locked_status")[0];
            var new_state = current_state == "t" ? "f" : "t";
            
            // If the new state is equal to the original, no highlight
            if (new_state == og_state) {
                lock_table_cell.classList.remove("modified");
            } else {
                // The new states are different, highlight
                lock_table_cell.classList.add("modified");
            }
            
            // Set the actual state
            lock_button.setAttribute(
                "data-locked_status",
                new_state + og_state
            );
            // Set the emoji
            lock_button.setAttribute(
                "value",
                new_state == "t" ? this.symbols.locked : this.symbols.unlocked
            );
        },

        /**
         * Change the QA status button between QA states
         * First character is the current state
         * Second character is the OG state
         */
        change_qa_status(image_name, qa_type, subimage_idx) {
            var id = image_name + "_" + qa_type;
            if (qa_type == "keypoint_qa") {
                id += subimage_idx;
            }
            var qa_button = document.getElementById(id);
            var qa_table_cell = document.getElementById(id + "_cell");
            var og_state = qa_button.getAttribute("data-qa_status")[1];
            var current_state = qa_button.getAttribute("data-qa_status")[0];
            var new_state =
                current_state == "n" ? "a" : current_state == "a" ? "r" : "n";

            // Highlight logic
            if (new_state == og_state) {
                qa_table_cell.classList.remove("modified");
            } else {
                qa_table_cell.classList.add("modified");
            }

            qa_button.setAttribute("data-qa_status", new_state + og_state);
            qa_button.setAttribute(
                "value",
                new_state == "n" ?
                this.symbols.qa_not_done :
                new_state == "a" ?
                this.symbols.qa_approved :
                this.symbols.qa_rejected
            );
        },

        /**
         * Modify the qa status for a whole group at once
         * Used for row_qa or ppa_qa
         */
        change_all_status(qa_type) {
            for (let idx in this.image_data) {
                this.change_qa_status(idx, qa_type, null);
            }
        },

        unlock_all_locks() {
            // Set all locks to unlocked
            for (let image_name in this.image_data) {
                // TODO this is a lot of redundant code 
                var lock_button = document.getElementById(image_name + "_lock");
                var lock_table_cell = document.getElementById(
                    image_name + "_lock_cell"
                );
                var og_state = lock_button.getAttribute("data-locked_status")[1];
                var new_state = "f";
                
                // If the new state is equal to the original, no highlight
                if (new_state == og_state) {
                    lock_table_cell.classList.remove("modified");
                } else {
                    // The new states are different, highlight
                    lock_table_cell.classList.add("modified");
                }
                
                // Set the actual state
                lock_button.setAttribute(
                    "data-locked_status",
                    new_state + og_state
                );
                // Set the emoji
                lock_button.setAttribute(
                    "value",
                    this.symbols.unlocked
                );
            }
        },

        mark_all_not_done(qa_type) {
            for (let image_name in this.image_data) {
                var id = image_name + "_" + qa_type;
                var qa_button = document.getElementById(id);
                var qa_table_cell = document.getElementById(id + "_cell");
                var og_state = qa_button.getAttribute("data-qa_status")[1];
                var new_state = "n";

                // Highlight logic
                if (new_state == og_state) {
                    qa_table_cell.classList.remove("modified");
                } else {
                    qa_table_cell.classList.add("modified");
                }

                qa_button.setAttribute("data-qa_status", new_state + og_state);
                qa_button.setAttribute(
                    "value",
                    new_state == "n" ?
                    this.symbols.qa_not_done :
                    new_state == "a" ?
                    this.symbols.qa_approved :
                    this.symbols.qa_rejected
                );
            }
        },

        /**
         * Get the QA status of a button, returns it, and resets the highlighting.
         */
        get_qa_status_and_update_button(id) {
            var qa_button = document.getElementById(id);
            var qa_cell = document.getElementById(id + "_cell");
            var qa_status;
            if (id.includes("lock")) {
                qa_status = qa_button.getAttribute("data-locked_status");
            } else {
                qa_status = qa_button.getAttribute("data-qa_status");
            }
            var current_state = qa_status[0];
            qa_button.setAttribute(
                "data-qa_status",
                current_state + current_state
            );
            // qa_button.setAttribute(
            //     "value",
            //     current_state == "n" ? this.symbols.qa_not_done
            //         : current_state == "a" ? this.symbols.qa_approved
            //         : current_state == "r" ? this.symbols.qa_rejected
            //         : current_state == "t" ? this.symbols.locked
            //         : current_state == "f" ? this.symbols.unlocked
            //         : "Spaghetti"
            // )
            // qa_cell.style = "text-align:center";
            qa_cell.classList.remove("modified");

            // return current_state;
            // return actual status key
            return this.qa_status_letter_to_key(qa_status[0])
        },

        qa_status_letter_to_key(qa_status_letter) {
            switch (qa_status_letter) {
                case "a":
                    return "approved";
                case "n":
                    return "not_done";
                case "r": 
                    return "rejected";
                case "t":
                    return true;
                case "f":
                    return false;
            }
            throw "Invalid QA Status Letter.";
        },

        is_modified(id) {
            return document.getElementById(id + "_cell").classList.contains("modified")
        },

        create_update_dict(image_name, qa_type, subimage_idx = -1) {
            // qa_type: lock, ppa_qa, row_qa, keypoint_qa
            var id;
            if (qa_type === "keypoint_qa") {
                id = image_name + "_" + qa_type + subimage_idx; 
                return {
                    "image_name": image_name,
                    "qa_type": qa_type,
                    "qa_val": this.get_qa_status_and_update_button(id),
                    "subimage_idx": subimage_idx
                };
            } else if (qa_type === "lock") {
                id = image_name + "_" + qa_type;
                return {
                    "image_name": image_name,
                    "qa_type": "IS_LOCKED",
                    "qa_val": this.get_qa_status_and_update_button(id),
                };
            } else {
                id = image_name + "_" + qa_type;
                return {
                    "image_name": image_name,
                    "qa_type": qa_type,
                    "qa_val": this.get_qa_status_and_update_button(id),
                };
            }
        },

        async modify_progress_json() {
            var image_names = Object.keys(this.image_data);

            var params = {
                field_json: this.field_json,
                update_items: [],
            };

            var image_name;
            for (var i = 0; i < image_names.length; i++) {
                image_name = image_names[i];
                if (this.is_modified(image_name + "_lock")) {
                    params.update_items.push(this.create_update_dict(image_name, "lock"));
                }
                if (this.is_modified(image_name + "_row_qa")) {
                    params.update_items.push(this.create_update_dict(image_name, "row_qa"));
                }
                if (this.is_modified(image_name + "_ppa_qa")) {
                    params.update_items.push(this.create_update_dict(image_name, "ppa_qa"));
                }
                if (this.is_modified(image_name + "_gender_qa")) {
                    params.update_items.push(this.create_update_dict(image_name, "gender_qa"));
                }
                // Check subimages
                for (var j = 0; j < this.image_data[image_name].subimages.length; j++) {
                    if (this.is_modified(image_name + "_keypoint_qa" + j)) {
                        params.update_items.push(this.create_update_dict(image_name, "keypoint_qa", j));
                    }
                }
            }

            console.log(params);
            try {
                // Set raise_error to true to show error messages to the user
                await batch_update_qa(params, true);
            } catch (error) {
                let error_msg = error.message;
                if (error.response) {
                    error_msg = error.response.data;
                } 
                alert("Failed to save changes: " + error_msg);
            }

            await this.getProgressData();
        },
    },
};
</script>
