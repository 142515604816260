// Shared functions for components that navigate btwn views (ie Progress screen)
import { get_username } from "./qa_utils"

/**
 * Launch a PPA QA for a given image/subimage combo
 * @param {*} page "this" of the active component 
 * @param {*} image_name image name
 * @param {*} subimage_idx subimage idx
 */
export function launch_ppa_qa(page, image_name, subimage_idx) {
    if (image_is_locked(page, image_name)) {
        alert("Image locked by " + page.image_data[image_name].locked_by + ", navigation canceled.");
    } else {
        page.$router.push({
            name: "PPA QA",
            params: {
                field_name: page.field_name,
                image_name: image_name,
                subimage_idx: subimage_idx,
                n_h_subimages: page.progress_json["files"][image_name][
                    "n_h_subimages"
                ],
                n_w_subimages: page.progress_json["files"][image_name][
                    "n_w_subimages"
                ],
            },
        });
    }
}

/**
 * Launch a Row QA for a given image
 * @param {*} page "this" of the active component 
 * @param {*} image_name image name
 * @param {boolean} grid whether to nav back to grid screen
 * @param {boolean} gender_qa whether to start gender qa
 * @param {boolean} new_tab whether to launch in new tab
 */
export function launch_row_qa(page, image_name, grid=false, gender_qa=false, new_tab=false) {
    if (image_is_locked(page, image_name)) {
        alert("Image locked by " + page.image_data[image_name].locked_by + ", navigation canceled.");
    } else if (new_tab) {
        let route_params = {
            field_name: page.field_name,
            image_name: image_name,
            grid: grid,
            gender_qa: gender_qa,
        }
        const route_data = page.$router.resolve(
            {
                name: "Row QA",
                query: route_params,
                params: route_params,
            }
        )
        window.open(
            route_data.href,
            '_blank',


        )
        window.open(route_data.href, '_blank');
    } else {
        page.$router.push({
            name: "Row QA",
            params: {
                field_name: page.field_name,
                image_name: image_name,
                grid: grid,
                gender_qa: gender_qa,
            },
        });
    }
    
}

/**
 * Check if an image is currently locked by someone other than the user
 * @param {*} page "this" of the active component
 * @param {*} image_name image name
 */
function image_is_locked(page, image_name) {
    let user = get_username(page);
    return page.image_data[image_name].IS_LOCKED && page.image_data[image_name].locked_by != user;
}

/**
 * Hide the internal and/or external navbar. If no options are provided, hide the entire navbar div
 * 
 * @param {boolean} hide_internal whether to hide internal navbar
 * @param {boolean} hide_external whether to hide external navbar
 */
export function hide_navbar(hide_internal = false, hide_external = false) {
    if (hide_internal) {
        document.getElementById("internal-navbar").classList.add("hidden");
    }
    if (hide_external) {
        document.getElementById("external-navbar").classList.add("hidden");
    }
    // If no options are provided, hide entire navbar
    if (!hide_internal && !hide_external) {
        document.getElementById("navbar").classList.add("hidden");
    }
}

/**
 * Un-hide the internal and/or external navbar. If no options are provided, unhide the entire navbar div
 * 
 * @param {boolean} show_internal whether to show internal navbar
 * @param {boolean} show_external whether to show external navbar
 */
export function show_navbar(show_internal = false, show_external = false) {
    if (show_internal) {
        document.getElementById("internal-navbar").classList.remove("hidden");
    }
    if (show_external) {
        document.getElementById("external-navbar").classList.remove("hidden");
    }
    // If no options are provided, show entire navbar
    if (!show_internal && !show_external) {
        document.getElementById("navbar").classList.remove("hidden");
    }
}