var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"progress"}},[_c('br'),_c('h2',[_vm._v("Progress")]),_c('p',[_vm._v("Field Name: "+_vm._s(_vm.field_name))]),_c('p',[_vm._v(_vm._s(Object.keys(_vm.image_data).length)+" Images")]),_c('input',{attrs:{"type":"button","value":"Save Changes"},on:{"click":function($event){return _vm.modify_progress_json()}}}),_c('input',{attrs:{"type":"button","value":"Unlock All"},on:{"click":function($event){return _vm.unlock_all_locks()}}}),_c('br'),_c('br'),_c('table',{staticClass:"mainprogresstable"},[_c('input',{staticClass:"change-all-ppa-btn",attrs:{"type":"button","value":"Change All PPA QA"},on:{"click":function($event){return _vm.change_all_status('ppa_qa')}}}),_c('input',{staticClass:"change-all-row-btn",attrs:{"type":"button","value":"Change All Row QA"},on:{"click":function($event){return _vm.change_all_status('row_qa')}}}),_c('input',{staticClass:"not-done-ppa-btn",attrs:{"type":"button","value":"Mark All 'Not Done' PPA QA"},on:{"click":function($event){return _vm.mark_all_not_done('ppa_qa')}}}),_c('input',{staticClass:"not-done-row-btn",attrs:{"type":"button","value":"Mark All 'Not Done' Row QA"},on:{"click":function($event){return _vm.mark_all_not_done('row_qa')}}}),_vm._m(0),_vm._l((_vm.image_data_arr),function(ref,id){
var image_name = ref[0];
var image = ref[1];
return _c('tbody',{key:id,class:[
                    {
                        allcomplete:
                            image.ppa_qa == 'approved' &&
                            image.row_qa == 'approved' &&
                            image.gender_qa == 'approved',
                        allrejected:
                            image.ppa_qa == 'rejected' &&
                            image.row_qa == 'rejected' &&
                            image.gender_qa == 'rejected',
                        incomplete:
                            image.ppa_qa == 'not_done' ||
                            image.ppa_qa == 'not_done' ||
                            image.gender_qa == 'not_done',
                    },
                    _vm.heavybordertopsides ]},[_vm._m(1,true),_c('tr',[_c('td',[_c('b',{staticClass:"thick"},[_vm._v(_vm._s(id))])]),_c('td',{staticStyle:{"text-align":"center"},attrs:{"id":image_name + '_lock_cell'}},[(image.IS_LOCKED == false)?_c('input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"button","id":image_name + '_lock',"value":_vm.symbols.unlocked,"data-locked_status":"ff","title":_vm.is_modified_stream[image_name]},on:{"click":function($event){return _vm.change_lock(image_name)}}}):(image.IS_LOCKED)?_c('input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"locked",attrs:{"type":"button","id":image_name + '_lock',"value":_vm.symbols.locked,"data-locked_status":"tt","title":_vm.is_modified_stream[image_name]},on:{"click":function($event){return _vm.change_lock(image_name)}}}):_vm._e(),(image.IS_LOCKED)?_c('b',{staticClass:"thick"},[_vm._v(" "+_vm._s(_vm.truncate_username(image.locked_by))+" ")]):_vm._e()]),_c('td',[_c('b',{staticClass:"thick"},[_vm._v(_vm._s(image_name))])])]),_c('tr',{staticClass:"heavyborderbottomsides"},[_c('td',{attrs:{"colspan":"3"}},[_c('table',{staticStyle:{"padding":"0px","border":"0px"},attrs:{"align":"center"}},[_c('tr',[_c('td',{staticStyle:{"border":"0px"}},[_c('table',{class:[
                                            {
                                                complete:
                                                    image.ppa_qa == 'approved',
                                                rejected:
                                                    image.ppa_qa == 'rejected',
                                                incomplete:
                                                    image.ppa_qa == 'not_done',
                                            },
                                            _vm.subimagetable ]},[_vm._m(2,true),_c('tr',[_c('td',[_c('input',{attrs:{"type":"button","value":"🚀"},on:{"click":function($event){return _vm.launch_ppa_qa(
                                                            image_name,
                                                            0
                                                        )}}})]),_c('td',{staticClass:"button_td",attrs:{"id":image_name + '_ppa_qa_cell'}},[(
                                                        image.ppa_qa ==
                                                        'not_done'
                                                    )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_not_done,"data-qa_status":"nn","id":image_name + '_ppa_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )}}}):(
                                                        image.ppa_qa ==
                                                        'rejected'
                                                    )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_rejected,"data-qa_status":"rr","id":image_name + '_ppa_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )}}}):(
                                                        image.ppa_qa ==
                                                        'approved'
                                                    )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_approved,"data-qa_status":"aa","id":image_name + '_ppa_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                            image_name,
                                                            'ppa_qa',
                                                            null
                                                        )}}}):_vm._e()])])])]),_c('td',{staticStyle:{"border":"0px","width":"80%"}},[_c('table',{staticClass:"subimagetable",staticStyle:{"margin":"0px","border":"0px","padding":"0px"}},_vm._l((image['subimages']),function(subimage,subimage_idx){return _c('td',{key:subimage_idx,staticStyle:{"border":"0px","margin":"0px","padding":"0px"}},[_c('table',{staticClass:"subimagetable"},[_c('tbody',{class:[
                                                        {
                                                            complete:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'approved',
                                                            rejected:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'rejected',
                                                            incomplete:
                                                                subimage[
                                                                    'keypoint_qa'
                                                                ] == 'not_done',
                                                        } ]},[_c('tr',[_c('td',[_c('b',{staticClass:"thick"},[_vm._v(_vm._s(subimage_idx + 1))])]),_c('td',{staticClass:"button_td",attrs:{"id":image_name +
                                                                '_keypoint_qa' +
                                                                subimage_idx +
                                                                '_cell'}},[(
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'not_done'
                                                                )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_not_done,"data-qa_status":"nn","id":image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx},on:{"click":function($event){return _vm.change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )}}}):(
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'approved'
                                                                )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_approved,"data-qa_status":"aa","id":image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx},on:{"click":function($event){return _vm.change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )}}}):(
                                                                    subimage[
                                                                        'keypoint_qa'
                                                                    ] ==
                                                                    'rejected'
                                                                )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_rejected,"data-qa_status":"rr","id":image_name +
                                                                    '_keypoint_qa' +
                                                                    subimage_idx},on:{"click":function($event){return _vm.change_qa_status(
                                                                        image_name,
                                                                        'keypoint_qa',
                                                                        subimage_idx
                                                                    )}}}):_vm._e()])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('input',{attrs:{"type":"button","value":"🚀"},on:{"click":function($event){return _vm.launch_ppa_qa(
                                                                        image_name,
                                                                        subimage_idx
                                                                    )}}})])])])])])}),0)]),_c('td',{staticStyle:{"border":"0px"}},[_c('table',{class:[
                                            {
                                                complete:
                                                    image.row_qa == 'approved',
                                                rejected:
                                                    image.row_qa == 'rejected',
                                                incomplete:
                                                    image.row_qa == 'not_done',
                                            },
                                            _vm.subimagetable ]},[_c('tbody',[_vm._m(3,true),_c('tr',[_c('td',[_c('input',{attrs:{"type":"button","value":"🚀"},on:{"click":function($event){return _vm.launch_row_qa(
                                                                image_name,
                                                                false
                                                            )}}})]),_c('td',{staticClass:"button_td",attrs:{"id":image_name +
                                                        '_row_qa_cell'}},[(
                                                            image.row_qa ==
                                                            'not_done'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_not_done,"data-qa_status":"nn","id":image_name +
                                                            '_row_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )}}}):(
                                                            image.row_qa ==
                                                            'rejected'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_rejected,"data-qa_status":"rr","id":image_name +
                                                            '_row_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )}}}):(
                                                            image.row_qa ==
                                                            'approved'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_approved,"data-qa_status":"aa","id":image_name +
                                                            '_row_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'row_qa',
                                                                null
                                                            )}}}):_vm._e()])])])])]),_c('td',{staticStyle:{"border":"0px"}},[_c('table',{class:[
                                            {
                                                complete:
                                                    image.gender_qa == 'approved',
                                                rejected:
                                                    image.gender_qa == 'rejected',
                                                incomplete:
                                                    image.gender_qa == 'not_done',
                                            },
                                            _vm.subimagetable ]},[_c('tbody',[_vm._m(4,true),_c('tr',[_c('td',[_c('input',{attrs:{"type":"button","value":"🚀"},on:{"click":function($event){return _vm.launch_row_qa(
                                                                image_name,
                                                                true
                                                            )}}})]),_c('td',{staticClass:"button_td",attrs:{"id":image_name +
                                                        '_gender_qa_cell'}},[(
                                                            image.gender_qa ==
                                                            'not_done'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_not_done,"data-qa_status":"nn","id":image_name +
                                                            '_gender_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )}}}):(
                                                            image.gender_qa ==
                                                            'rejected'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_rejected,"data-qa_status":"rr","id":image_name +
                                                            '_gender_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )}}}):(
                                                            image.gender_qa ==
                                                            'approved'
                                                        )?_c('input',{attrs:{"type":"button","value":_vm.symbols.qa_approved,"data-qa_status":"aa","id":image_name +
                                                            '_gender_qa'},on:{"click":function($event){return _vm.change_qa_status(
                                                                image_name,
                                                                'gender_qa',
                                                                null
                                                            )}}}):_vm._e()])])])])])])])])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Image ID")]),_c('th',[_vm._v("File Lock")]),_c('th',[_vm._v("Image Name")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"noborder"},[_c('th',{staticClass:"noborder"}),_c('th',{staticClass:"noborder"}),_c('th',{staticClass:"noborder"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b',{staticClass:"thick"},[_vm._v("PPA QA")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b',{staticClass:"thick"},[_vm._v("Row QA")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b',{staticClass:"thick"},[_vm._v("Gender QA")])])])}]

export { render, staticRenderFns }