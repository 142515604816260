// API utilities 
import axios from "axios";
import { refreshTokenIfExpired } from "../utils/cognito";

/**
 * Hit a terraform API endpoint and return the response.
 * 
 * @param {string} method Method to use (ie GET, POST, etc.)
 * @param {string} endpoint Endpoint to hit
 * @param {object} params Params to pass to the API endpoint
 * @param {object} data Data to pass to the API endpoint
 * @param {number} page Page number to request
 * @returns API response
 */
export async function hit_terraform_api(method, endpoint, params = null, data = null, page = 1) {
    // Add leading slash if not present
    if (endpoint[0] !== "/") {
        endpoint = "/" + endpoint;
    }
    // Add page to the endpoint
    if (page > 1) {
        endpoint += `?page=${page}`;
    }

    let url = process.env.VUE_APP_API_URL + endpoint
    console.log(url)
    if (params !== null) {
        for (let key in params) {
            if (params[key] === null) {
                // If a param value is null, remove it
                delete params[key];
            } else if (Array.isArray(params[key])) {
                // If a param value is an array, convert it to a comma-separated string
                params[key] = params[key].join(",");
            }
        }
        url = axios.getUri({
            url: url,
            params: params
        });
    }

    return await axios({
        method: method,
        url: url,
        data: data === null ? {} : data,
    })
}

/**
 * Util to consolidate API function calls.
 * 
 * @param {string} method Method to use (ie GET, POST, etc.)
 * @param {string} endpoint Endpoint to hit
 * @param {string} data_key Key to pull data from 
 * @param {object} params Params to pass to the API endpoint.
 * @param {object} data Data to pass to the API endpoint.
 * @returns API response
 */
 export async function api_consolidator(method, endpoint, data_key = null, params = null, data = null) {
    let response = await hit_terraform_api(method, endpoint, params, data);
    if (data_key === null) {
        if (response.data === null) {
            return response.status === 200;
        } else {
            return response.data
        }
    } else {
        // Pagination
        let ret = response.data[data_key];
        if (response.data.pages > 1) {
            for (let page = 2; page <= response.data.pages; page++) {
                let page_response = await hit_terraform_api(method, endpoint, params, data, page);
                ret = ret.concat(page_response.data[data_key]);
            }
        }
        return ret;
    }
}

/**
 * Util to consolidate API function calls, with an error handler and optional callback.
 * 
 * @param {string} method Method to use (ie GET, POST, etc.)
 * @param {string} endpoint Endpoint to hit
 * @param {string} data_key Key to pull data from 
 * @param {object} params Params to pass to the API endpoint.
 * @param {object} data Data to pass to the API endpoint.
 * @param {Function} callback Optional callback to run upon completion.
 * @param {boolean} raise_error Whether to raise an error or return null. 
 * @returns Response or error message
 */
export async function api_consolidator_error_handler(
    method, 
    endpoint, 
    data_key = null, 
    params = null, 
    data = null, 
    callback = null,
    raise_error = false,
) {
    // Refresh the auth token if it has expired
    await refreshTokenIfExpired();
    try {
        let response = await api_consolidator(method, endpoint, data_key, params, data);
        if (callback) {
            return callback(response);
        }
        return response;
    } catch (error) {
        console.error(error);
        if (raise_error) {
            // When user specifies to raise an error, throw the error
            throw error;
        } else {
            // Otherwise suppress the error and return null
            return null;
        }
    }
}
