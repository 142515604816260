// Util functions for displaying things

/**
 * Trims the text to a certain length and adds '...' at the end
 * 
 * @param {string} text Text to trim
 * @param {number} max_length Maximum number of characters to display
 * @returns {string} Trimmed text
 */
export function trimText(text, max_length = 40) {
  if (text.length > max_length) {
    return text.slice(0, max_length) + '...';
  }
  return text;
}